// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBIOcpzl1xTxNIVoVJ7pxcAdBGlLo8tHto",
    authDomain: "linky.app",
    databaseURL: "https://the-linky-app.firebaseio.com",
    projectId: "the-linky-app",
    storageBucket: "the-linky-app.appspot.com",
    messagingSenderId: "659065271273",
    appId: "1:659065271273:web:90e311f0a5c8da2ff91178",
    //measurementId: "G-G2VMWYCF0V"
  },
  functions: {
    stripe: 'prod'
  },
  googleAnalytics: {
    domain: 'auto',
    trackingId: 'UA-45892524-3' // replace with your Tracking Id
  },
  stripe: 'pk_test_M4BMpREKyaYD4PIqsf7rxqrV00MegURA3w',
  unsplash: "DjY1xh377gzoCfvyJdY0GBWKMa1ctO1fb3tuDoTTCF8",
  unsplashsecret: "XZmRaLDkc0wU1tamuwsKiXZMi5dHLimJUbcNwdPR63c"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
