import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  closeWith(name) {
    this.modalCtrl.dismiss(name)
  }

}
