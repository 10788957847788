import { Component, Input, OnInit } from '@angular/core';
import { getDoc } from 'firebase/firestore/lite';
import { doc } from 'firebase/firestore/lite';
import { firestore } from 'src/app/app.module';

class UserClass {
  displayName!: string;
  uid!: string;
  photo!: string;
  pageUrl!: string;
  url!: string;

  constructor(dvalue: any) {
    if(dvalue.exists == false) return;
    this.displayName = dvalue.data().displayName;
    this.uid = dvalue.data().uid;
    this.photo = dvalue.data().img;
    this.url = dvalue.data().url;
    this.pageUrl = 'https://linky.app/' + dvalue.data().url
  }
}
@Component({
  selector: 'app-usertoolbar',
  templateUrl: './usertoolbar.component.html',
  styleUrls: ['./usertoolbar.component.scss'],
})
export class UsertoolbarComponent implements OnInit {
  @Input() userId: string;
  userData: UserClass;
  pageUrl: string = '';

  constructor() { 

  }

  async ngOnInit() {
    console.log(this.userId)
    this.userData = new UserClass( await getDoc(doc(firestore, `pages/${this.userId}`)));
  }

}
