import { Component, OnInit, Input, ViewChild, ElementRef, TemplateRef, AfterViewInit, ViewContainerRef, EventEmitter, Output, ChangeDetectorRef, HostListener } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { CssService } from '../services/css.service';
import { ModalController, AlertController, ActionSheetController, PopoverController } from '@ionic/angular';
import { ThemeEditorComponent } from './theme-editor/theme-editor.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ColorComponent } from './color/color.component';
import { StylePipe } from './pipes/style-pipe.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { httpsCallable } from 'firebase/functions';
import { UserService } from '../services/user.service';
import { BehaviorSubject, Observable } from 'rxjs';

import { firestore, auth, functions } from 'src/app/app.module';
import { getDoc } from 'firebase/firestore/lite';
import { addDoc, collection, doc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore/lite';
import { PhotoComponent } from './photo.bak/photo.component';
import { SettingsComponent } from './settings/settings.component';
import { MiniloginComponent } from '../minilogin/minilogin.component';

export interface CSSSTYLE {
  created: string;
  css: any;
  imports: number;
  name: string;
  updated: string;
  published: boolean;
  userId: string;
}

@Component({
  selector: 'app-style',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.scss'],
})

export class StyleComponent implements OnInit, AfterViewInit {
  @Input() demo: boolean = false;
  @Input() mini: boolean = false;
  @Input() style: any;
  @Input() block: any;
  @Input() userId: string;
  @Input() pageId: string;
  @Input() blockId: string;
  @ViewChild('elementselector') elementselector: any;
  @ViewChild('unitselector') unitselector: any;
  @ViewChild('color') colormodule: any;
  @Output() picked: EventEmitter<any> = new EventEmitter();
  gradients: { name: string; css: string; }[];
  fonts: { font: string; }[];
  units: string[];
  selected: any;
  elementvalone: any;
  elementvaltwo: any;
  elementvalthree: any;
  elementvalfour: any;
  elementtype: any;
  expanded: boolean = true;
  number: number;
  color: { custom: any; input: any; name: any; };
  components: any[];
  fontangle: number;
  fontpercentage: number;
  percentage: number;
  mainelm: any;
  preview: { css: any, active: any, before: any, after: any };
  unitval: string;
  @ViewChild('cardContent', { read: ElementRef }) cardContent: ElementRef;
  get footerStyle() {
    const baseStyle = {
      transition: 'all 0.3s ease-in-out',
      overflow: 'hidden',
      position: 'fixed',
      bottom: '0',
      left: '0',
      right: '0',
      'background-color': 'transparent'
    };

    if (this.expanded) {
      const cardHeight = this.cardContent?.nativeElement.offsetHeight || 200;
      return {
        ...baseStyle,
        height: `auto`, // Additional 50px for the button
        'max-height': '80vh' // Limit max height to 80% of viewport height
      };
    } else {
      const cardHeight = this.cardContent?.nativeElement.offsetHeight || 200;
      return {
        ...baseStyle,
        height: `${85}px` // Height to show just the expand button
      };
    }
  }
  linewidth: any;
  radiusnumber: number;
  linestyle: any;
  backgrounditems: any;
  @ViewChild('templatePortalContent') templatePortalContent: TemplateRef<any>;
  component: { linestyle: boolean; background: any; color: boolean; font: boolean; fontsize: boolean; border: boolean; length: boolean; url: boolean; image: boolean; fontfamily: boolean; fontstyle: boolean; fontweight: boolean; fontvariant: boolean; fontstretch: boolean; percentage: boolean; };
  font: { family: string; height: string; weight: string; size: string; style: string; stretch: string; variant: string; };
  bordermodule: { selected: any; border: boolean; radius: boolean; style: boolean; color: boolean; width: boolean; image: boolean };
  borderitems: { name: string; elm: string[]; icon: string; selected: boolean; value: string; }[];
  bkmodule: { selected: string; image: boolean; color: boolean; position: boolean, size: boolean; repeat: boolean; attachment: boolean; box: boolean; };
  componentPortal: ComponentPortal<unknown>;
  templatePortal: TemplatePortal<any>;
  _viewContainerRef: ViewContainerRef;
  selectedPortal: any;
  hasvalue: boolean;
  imagemodule: { url: boolean; image: boolean; gradient: boolean; upload: boolean; };
  backgroundvals: { image: string; color: string; position: string; size: string; repeat: string; attachment: string; box: string; };
  bordervals: { style: { current: any, top: any; bottom: any; right: any; left: any; selected: any; }; color: { current: any, top: any; bottom: any; right: any; left: any; selected: any; }; radius: { current: any, top: any; bottom: any; right: any; left: any; selected: any; }; width: { current: any, top: any; bottom: any; right: any; left: any; selected: any; }; image: { current: any, top: any; bottom: any; right: any; left: any; selected: any; }; };
  radiusitems: ({ name: string; elm: string; icons: string[]; } | { name: string; icons: string[]; elm?: undefined; })[];
  pagepreview: void;
  selectors2: any[] = [];
  showpreview: boolean = true;
  codeedit: boolean = false;
  showcode: boolean = true;
  downloadJsonHref: any;
  styleName: any;
  editmode: boolean = false;
  editKey: string;
  view: any = 'create';
  userStyles: Array<any>;
  stylePublished: boolean;
  libcodeselect: any;
  publishedStyles: any;
  userContent: any;
  content: Observable<CSSSTYLE>;
  selectedPre: number;
  blockRef: string;
  selectedSudo: string = 'css';
  stateHistory: BehaviorSubject<number> = new BehaviorSubject(null)

  constructor(
    public css: CssService,
    private sanitizer: DomSanitizer,
    public router: Router,
    public route: ActivatedRoute,
    public alertCtrl: AlertController,
    private popover: PopoverController, 
    public actionSheetController: ActionSheetController,
    private stylePipe: StylePipe,
    private clipboard: Clipboard,
    public snackBar: MatSnackBar,
    public modalCtrl: ModalController,
    public userService: UserService,
    public cdr: ChangeDetectorRef
  ) {
    this.stateHistory.subscribe(async (num) => {
      if (num = 1) this.loadUserStyles()
      if (num = 2) this.close()
      if (num = 6) this.resetpr()
      if (num = 8) this.ngOnInit()
    })
    userService.userSub.subscribe(async (user) => {
      console.log('userState Updated', user)
      if (user && user.uid != null) {
        this.userId = user.uid;
        this.loadUserStyles()
      } else {
        this.userId = null;
        this.userStyles = null;
      }
    });

    this.fonts = this.css.fonts
    this.gradients = this.css.gradients
    this.units = this.css.units
    this.css.font = {
      family: this.css.fonts2,
      height: this.css.options['font-weight'].values,
      weight: this.css.options['font-weight'].values,
      size: this.css.options['font-size'].values,
      style: this.css.options['font-style'].values,
      stretch: this.css.options['font-stretch'].values,
      variant: this.css.fontvariant
    }

    if (this.css && this.css.selectors) {
      let val = this.css.selectors
      val.forEach(element => {
        this.selectors2.push(element[1]);
      });
    }

    this.css.backgrounditems = {
      image: ['<image>'],
      color: ['<color>'],
      position: ['center', 'Top', 'Bottom', '<length-percentage>'],
      repeat: ['repeat-x', 'repeat-y', 'repeat', 'space', 'round', 'no-repeat', '{1,2}'],
      attachment: ['scroll', 'fixed', 'local'],
      size: ['<length-percentage>', 'auto', '{1,2}', 'cover', 'contain'],
      box: ['border-box', 'padding-box', 'content-box']
    };

    this.components = []
    this.css.components.forEach(element => {
      this.components.push('/' + element + '/')
    });

    this.backgrounditems = [
      { name: 'Image', value: null, icon: 'camera', elm: ['<image>'], selected: false, },
      { name: 'Color', value: null, icon: 'brush', elm: ['<color>'], selected: false, },
      { name: 'Position', value: null, selected: false, icon: 'move', elm: ['center', 'Top', 'Bottom', '<length-percentage>'] },
      { name: 'Size', value: null, selected: false, icon: 'resize', elm: ['<length-percentage>', 'auto', '{1,2}', 'cover', 'contain'] },
      { name: 'Repeat', value: null, selected: false, icon: 'copy', elm: ['repeat-x', 'repeat-y', 'repeat', 'space', 'round', 'no-repeat', '{1,2}'] },
      { name: 'Attachment', value: null, selected: false, icon: 'document-attach', elm: ['scroll', 'fixed', 'local'] },
      { name: 'Box', value: null, selected: false, icon: 'cube', elm: ['border-box', 'padding-box', 'content-box'] }
    ]
    this.borderitems = [
      { name: 'All', elm: ['Top', 'Bottom', 'Right', 'Left'], icon: 'square', selected: false, value: 'ion-rb-114' },
      { name: 'Horizontals', elm: ['Right', 'Left'], icon: 'swap-horizontal', selected: false, value: 'ion-rb-115' },
      { name: 'Verticals', elm: ['Top', 'Bottom'], icon: 'swap-vertical', selected: false, value: 'ion-rb-116' },
      { name: 'Left', elm: ['Left'], icon: 'caret-back', selected: false, value: 'ion-rb-117' },
      { name: 'Top', elm: ['Top'], icon: 'caret-up', selected: false, value: 'ion-rb-118' },
      { name: 'Right', elm: ['Right'], icon: 'caret-forward', selected: false, value: 'ion-rb-119' },
      { name: 'Bottom', elm: ['Bottom'], icon: 'caret-down', selected: false, value: 'ion-rb-120' },
      { name: 'Top-Right', elm: ['Top', 'Right'], icon: 'scan-outline', selected: false, value: 'ion-rb-120' },
      { name: 'Top-Left', elm: ['Top', 'Left'], icon: 'scan-outline', selected: false, value: 'ion-rb-120' },
      { name: 'Bottom-Right', elm: ['Bottom', 'Right'], icon: 'scan-outline', selected: false, value: 'ion-rb-120' },
      { name: 'Bottom-Left', elm: ['Bottom', 'Left'], icon: 'scan-outline', selected: false, value: 'ion-rb-120' },
      { name: 'Top Right', elm: ['TopRight'], icon: 'scan-outline', selected: false, value: 'ion-rb-120' },
      { name: 'Top Left', elm: ['TopLeft'], icon: 'scan-outline', selected: false, value: 'ion-rb-120' },
      { name: 'Bottom Right', elm: ['BottomRight'], icon: 'scan-outline', selected: false, value: 'ion-rb-120' },
      { name: 'Bottom Left', elm: ['BottomLeft'], icon: 'scan-outline', selected: false, value: 'ion-rb-120' }
    ]

    this.radiusitems = [
      { name: 'Top right', elm: '', icons: ['caret-up', 'caret-back'] },
      { name: 'Top left', icons: ['caret-up', 'caret-forward'] },
      { name: 'Bottom right', icons: ['caret-down', 'caret-forward'] },
      { name: 'Bottom left', icons: ['caret-down', 'caret-back'] },
      { name: 'Right', icons: ['caret-forward', 'caret-up', 'caret-down'] },
      { name: 'Left', icons: ['caret-left', 'caret-up', 'caret-down'] },
      { name: 'Top', icons: ['caret-back', 'caret-up', 'caret-forward'] },
      { name: 'Bottom', icons: ['caret-left', 'caret-down', 'caret-forward'] },
    ]

    let borderitems2 = [
      { name: 'All', elm: ['Top', 'Bottom', 'Right', 'Left'], icon: 'square', selected: false, value: 'ion-rb-114' },
      { name: 'Horizontals', elm: ['Right', 'Left'], icon: 'swap-horizontal', selected: false, value: 'ion-rb-115' },
      { name: 'Verticals', elm: ['Top', 'Bottom'], icon: 'swap-vertical', selected: false, value: 'ion-rb-116' },
      { name: 'Left', elm: ['Left'], icon: 'caret-back', selected: false, value: 'ion-rb-117' },
      { name: 'Top', elm: ['Top'], icon: 'caret-up', selected: false, value: 'ion-rb-118' },
      { name: 'Right', elm: ['Right'], icon: 'caret-forward', selected: false, value: 'ion-rb-119' },
      { name: 'Bottom', elm: ['Bottom'], icon: 'caret-down', selected: false, value: 'ion-rb-120' }
    ]

    this.backgroundvals = {
      image: '',
      color: '',
      position: '',
      size: '',
      repeat: '',
      attachment: '',
      box: ''
    }
    if (this.style) {
      this.preview[this.selectedSudo] = this.style
    } else {
      this.font = {
        style: null,
        variant: null,
        family: null,
        stretch: null,
        height: null,
        weight: null,
        size: null
      }
      this.imagemodule = {
        url: null,
        image: null,
        gradient: null,
        upload: null
      }
      this.bordermodule = {
        border: true,
        radius: false,
        style: false,
        color: false,
        width: false,
        image: false,
        selected: null
      }
      this.bkmodule = {
        image: false,
        color: false,
        position: false,
        size: false,
        repeat: false,
        attachment: false,
        box: false,
        selected: null,
      }
      this.bordervals = {
        style: {
          current: null,
          top: null,
          bottom: null,
          right: null,
          left: null,
          selected: null
        },
        color: {
          current: null,
          top: null,
          bottom: null,
          right: null,
          left: null,
          selected: null
        },
        radius: {
          current: null,
          top: null,
          bottom: null,
          right: null,
          left: null,
          selected: null
        },
        width: {
          current: null,
          top: null,
          bottom: null,
          right: null,
          left: null,
          selected: null
        },
        image: {
          current: null,
          top: null,
          bottom: null,
          right: null,
          left: null,
          selected: null
        }
      }
      this.preview = { css: { height: '50px', width: '100px' }, before: {}, after: {}, active: {} }
    }
  }

  onContentClick(event: Event) {
    if (this.expanded) {
      this.expanded = false;
      this.cdr.detectChanges();
    }
  }

  onFooterClick(event: Event) {
    if (!this.expanded) {
      this.expanded = true;
      this.cdr.detectChanges();
    }
  }

  expand() {
    this.expanded = !this.expanded
    this.cdr.detectChanges()
  }

  close() {
    this.modalCtrl.dismiss().catch(err => {
      if (err) {
        this.router.navigateByUrl('/dashboard')
      }
    })
  }

  updateCodeItem(index: number, data: any) {
    console.info(index, data)
    console.info(this.preview.css)

    this.preview.css[data.key] = data.value
    this.cdr.detectChanges()
  }

  getImage(singleItem) {
    console.log(singleItem)
  }

  toggle(mode) {
    this.view = mode;
  }


  async presentGenerateStyle() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Generate Style',
      inputs: [
        {
          name: 'paragraph',
          id: 'paragraph',
          type: 'textarea',
          placeholder: 'Description'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

            console.log('Confirm Cancel');
          }
        }, {
          text: 'Generate',
          handler: async (alertData) => {
            const user = await this.loginModal()
            if(!user) return this.userMessage('Feature not available');
            console.log(alertData.paragraph);
            await this.generate(alertData.paragraph)
          }
        }
      ]
    });

    await alert.present()
    const { data } = await (await alert.onDidDismiss()).data;
  }

  async loginModal() {
    if(await auth.currentUser) return auth.currentUser;
    this.userMessage('login to continue ')
    const modal = await this.popover.create({
      component: MiniloginComponent,
    })
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return await auth?.currentUser
  }

  async generate(description: string = '', name: string = '') {
    const callable = httpsCallable(functions, 'generateStyle');
    const item = await callable({description: description, name: name }) as any
    console.info(item)
    if(item.error) return this.userMessage('Something went wrong, try again later')
    if (item && item.data) {
      const data = item.data
      this.preview['css'] = data
    }
  }

  filterCodeItem(item, index) {
    console.info('filterCodeItem')
    if (item.message == null) {
      console.info('test condition met')
      this.preview.css[item.key] = null
      this.preview.css = this.removeUndefined(this.preview.css)
      this.cdr.detectChanges()
    }
  }

  async loadUserStyles() {
    if (this.style) {
      this.preview = this.style
    }
    if (!this.userId) return this.snackBar.open('login to save styles', '', { duration: 5000 })
    this.userId = (await this.userService.getUser()).uid;
    this.userStyles = await (await getDocs(query(collection(firestore, 'styles'), where('userId', '==', this.userId.toString())))).docs.map((value) => { return value.data() as CSSSTYLE });
    this.publishedStyles = (await getDocs(query(collection(firestore, `styles`), where('published', '==', true)))).docs.map((data) => { return data.data() });
  }

  async publishStyle(pub, key) {
    console.log(pub, key)
    try {
      await updateDoc(doc(firestore, `styles/${key}`), { published: pub });
    } catch (error) {
      return this.warnMessage(error)
    }
    this.loadUserStyles()
    return this.userMessage('Saved changes')
  }

  displayLiblary(selected) {
    if (this.libcodeselect === selected) {
      this.libcodeselect = null;
    } else {
      this.libcodeselect = selected;
    }
    console.log(this.libcodeselect, selected)
  }


  async ngOnInit() {
    this.closecomponents()
    this.color = {
      custom: null,
      input: null,
      name: null
    }
    await this.loadDataStyle();
    this.route.params.subscribe(async (params: Params) => {
      var paramskey = params.key ? params.key : null
      if (params.key === 'Test') {
        return this.router.navigateByUrl('dashboard');
      }
      if (params && paramskey) {
        var res = params.key.toLowerCase()
        this.userContent = await (await getDoc(doc(firestore, `styles/${res}`)))
        if (this.userContent.exists) {
          this.content = this.userContent.data()
          return this.setpage()
        }
      }
    });
    if (this.blockId) {
      console.log(this.blockId, 'for page ', this.pageId);
      this.blockRef = `blocks/${this.blockId}`;
      this.userContent = await (await getDoc(doc(firestore, this.blockRef)))
      if (this.userContent.exists) {
        this.content = this.userContent.data()
        return this.setpage()
      }
    }
  }

  async loadDataStyle() {
    this.publishedStyles = (await getDocs(query(collection(firestore, `styles`), where('published', '==', true)))).docs.map((data) => { return data.data() });
    if (!this.block) return
    Object.keys(this.block).forEach(element => {
      this.preview[this.selectedSudo][element] = this.block[element]
    })
  }

  async setpage() {
    if (this.content && this.content != null) {
      this.content.toPromise().then((styleRef: CSSSTYLE) => {
        this.editmode = true;
        this.styleName = styleRef.name
        this.stylePublished = styleRef.published
        this.preview[this.selectedSudo] = styleRef.css
      })
    }
  }

  setName() {
    this.getInput("Name of style", "", "something memorable").then(val => {
      this.styleName = val
    })
  }

  ngAfterViewInit() {
    this.componentPortal = new ComponentPortal(ColorComponent);
    this.templatePortal = new TemplatePortal(this.templatePortalContent, this._viewContainerRef);
    this.startFrom()
  }

  element(event) {
    this.stateHistory.next(5)
    this.selected = null
    this.hasvalue = false;
    let transform = this.getselector(event.value)
    this.select(transform)

    this.selected = this.css.options[transform];
    if (this.selected && this.selected.type && this.selected.type === "fontFamily") {
      this.selected.values = this.css.fonts2
    }
    if (event.value in this.component) {
      this.showcomponent(event.value)
    }
    if (this.selected.values && this.selected.values.length && this.selected.values.length <= 1) {
      if (this.selected.values[0] = "<color>") {
        this.elementtype = this.selected.values[0]
        this.component.color = true;
      }
      if (event.value = 'color') {
        this.component.color = true;
      }
      if (event.value = 'background') {
        this.component.background = true;
      }
      console.log(this.selected)
    } else {
      if (this.selected.values) {
        let counter = setInterval(() => {
          if (this.elementselector) {
            this.elementselector.open()
            clearInterval(counter)
          }
        }, 100);
      }
    }
    this.resetpr()
  }

  getselector(name) {
    return this.css.cssselectors[name]
  }

  searchcomponent(name) {
    var isMatch = this.css.components.some(function (arrVal) {
      return name === arrVal;
    });
    if (name == 'border') {

    }
    return isMatch
  }

  async updateBlock(data: any) {
    this.snackBar.open('Saving', 'Ok!', {
      duration: 3000,
    });
    if (!data.id) data.id = this.blockId;
    try {
      return await setDoc(doc(firestore, `pages/${this.pageId}/blocks/${this.blockId}`), data);
    } catch (error) {
      this.snackBar.open('Something went wrong', 'Ok!', {
        duration: 3000,
        panelClass: 'warn'
      });
      return 'error'
    }
  }

  clearEdit() {
    this.editmode = false;
    this.editKey = null;
    this.styleName = null;
    this.cdr.detectChanges();
  }

  async importStyle(key, styleRef) {
    console.log(key)
    console.log(styleRef)
    this.preview.css = styleRef.css
    this.view = 'create'
    const contains = this.userStyles.find((value) => value.key === key)
    console.log(contains, this.userStyles.length)
    if (contains) {
      this.styleName = styleRef.name;
      this.editKey = key;
      this.editmode = true;
    } else {
      this.editKey = null;
      this.editmode = false;
    }
    this.stateHistory.next(6)
    addDoc(collection(firestore, `styles/${key}/stats`), {
      user: this.userId,
      page: this.blockId != null ? this.blockId : null
    }).then(() => {
      this.snackBar.open('Applied ' + styleRef.name, 'Close !!', {
        duration: 3000,
      }).onAction().subscribe(() => {
        this.modalCtrl.dismiss({
          data: styleRef.css,
          'dismissed': true
        });
      })
    }).catch((error) => {
      console.log(error)
      this.warnMessage('something went wrong')
    })
  }

  warnMessage(string: string) {
    this.snackBar.open(string, 'Ok!', {
      duration: 3000,
      panelClass: 'warn'
    });
  }

  userMessage(string: string) {
    this.snackBar.open(string, 'Ok !', {
      duration: 3000,
    })
  }

  uploadNew() {
    var data = {
      name: this.styleName ? this.styleName : '',
      created: Date.now(),
      css: this.stylePipe.transform(this.preview.css),
      userId: this.userId ? this.userId : 'Unknown',
      published: false
    }

    return addDoc(collection(firestore, 'styles'), data).then((savedRef) => {
      this.userMessage('Saved')
    }).catch(er => {
      this.warnMessage('Something Went Wrong')
    });
  }

  saveUpload() {
    if (!this.editmode) {
      this.userContent.update({
        name: this.styleName,
        active: this.preview.active,
        after: this.preview.before,
        css: this.stylePipe.transform(this.preview.css),
        updated: Date.now(),
        published: this.stylePublished
      });
      return;
    }
    if (this.editKey) {
      updateDoc(doc(firestore, `styles/${this.editKey}`), {
        name: this.styleName != null ? this.styleName : null,
        css: this.stylePipe.transform(this.preview.css),
        updated: Date.now(),
        published: this.stylePublished
      }).then((savedRef: void) => {
        this.userMessage('Updates Saved')
      }).catch(er => {
        console.log(er);
        this.warnMessage('Something Went Wrong')
      });
    }
  }

  async getInput(header, sub, hint) {
    const val = null
    let prompt = await this.alertCtrl.create({
      header: header ? header : 'Fill form',
      subHeader: sub ? sub : 'Enter a whole number',
      message: hint ? hint : null,
      inputs: [{
        name: 'value',
        type: 'text'
      }],
      buttons: [
        {
          text: 'Cancel'
        },
        {
          text: 'Add',
          handler: data => {
            return data;
          }
        }
      ]
    });
    prompt.present();
    return (await prompt.onDidDismiss()).data
  }

  async getpercentage(header, hint) {
    const val = null
    let prompt = await this.alertCtrl.create({
      header: header ? header : 'Percentage',
      subHeader: 'Enter a whole number',
      message: hint ? hint : null,
      inputs: [{
        name: 'num',
        placeholder: 'Number',
        type: 'number'
      }],
      buttons: [
        {
          text: 'Cancel'
        },
        {
          text: 'Add',
          handler: data => {
            return data.tableNumber;
          }
        }
      ]
    });
    prompt.present();
    return (await prompt.onDidDismiss()).data
  }

  select(element) {
    let truthy = this.searchcomponent(element)
    if (truthy) {
      let es = element.replace('>', '');
      let res = es.replace('<', '');
      if (res in this.component) {
        let resfin = res.replace('-', '')
        this.showcomponent(resfin)
      } else {
        console.log('Does not exisit in components')
        this.handlecomponent(element)
      }
    } else {
      if (this.searchbk(element)) {
        this.handlebk(element)
      } else {
        if (this.searchBorder(element)) {
          this.handleborder(element)
        } else {
          this.closecomponents()
          //console.log(this.mainelm)
          if (this.mainelm !== element) {
            this.product(this.mainelm, element)
          }
        }
      }
    }
  }


  searchbk(element) {
    let backgroundvalues = [
      '/images/',
      '/color/',
      '/size/',
      '/position/',
      '/repeat/',
      '/attachment/',
      '/size/',
      '/box/'
    ]

    for (const val of backgroundvalues) {
      if (val.toLowerCase().includes(element.toLowerCase())) {
        return true
      }
    }
  }

  searchBorder(element) {
    let bordervalues = [
      '/image/',
      '/style/',
      '/radius/',
      '/color/',
      '/width/',
    ]

    for (const val of bordervalues) {
      if (val.toLowerCase().includes(element.toLowerCase())) {
        return true
      }
    }
  }

  searchBr(element) {
    let bordervals = [

    ]
  }
  closecomponents() {
    this.component = {
      linestyle: false,
      color: false,
      length: false,
      url: false,
      image: false,
      font: false,
      percentage: false,
      fontfamily: false,
      fontstyle: false,
      fontweight: false,
      fontvariant: false,
      fontstretch: false,
      fontsize: false,
      border: false,
      background: false
    }
  }
  fontselector(value: any, name) {
    if (value === 'oblique <angle>') {
      this.getpercentage('Angle', 'between 1 - 360').then(num => {
        if (num) {
          this.css.font[name].push('oblique ' + num.values.num + 'deg');
          this.font[name] = 'oblique ' + num.values.num + 'deg'
          return
        }
      })
    }
    if (value === '<number>') {
      this.getpercentage('Number', 'between 1 - 1,000').then(num => {
        if (num) {
          this.css.font[name].push(num.values.num);
          this.font[name] = num.values.num
          return
        }
      })
    }
    if (value === '<percentage>') {
      this.getpercentage(null, '').then(num => {
        if (num) {
          this.css.font[name].push(num.values.num + '%')
          this.font[name] = num.values.num
          return
        }
      })
    } else {
      if (this.font[name] === null) {
        this.font[name] = value
      } else {
        this.font[name] = null
      }
    }
    if (value === '<relative-size>' || '<absolute-size>') {

      return
    } else {
      this.font[name] = value
    }
    this.resetpr()
  }

  fontdelete(value, name) {
    this.font[name] = null
    this.resetpr()
  }
  product(element, value) {
    if (value == 'on') {
      return alert('Err')
    }
    if (element !== value) {
      this.preview[this.selectedSudo][element] = value
      this.hasvalue = true;
    }
    let p = Object.keys(this.preview[this.selectedSudo]).forEach((key) => (this.preview[this.selectedSudo][key] == null) && delete this.preview[this.selectedSudo][key]);
    this.pagepreview = p;
    this.resetpr();
  }

  toggleStyle(sudo) {
    this.selectedSudo = sudo;
  }

  handleborder(name) {
    this.bordermodule = {
      border: false,
      radius: false,
      style: false,
      color: false,
      width: false,
      image: false,
      selected: null
    };
    this.bordermodule.selected = { val: null, name: name, sides: null };
    this.bordermodule[name] = true
    this.resetpr()
  }

  handleimge(val) {
    this.imagemodule = {
      url: false,
      image: false,
      gradient: false,
      upload: false
    }
    this.imagemodule[val] = true
    this.resetpr()
  }

  showpr() {
    this.cdr.detectChanges();
  }

  resetpr() {
    if (!this.preview) return
    this.preview[this.selectedSudo] = this.deleteUndefined(this.preview[this.selectedSudo])
    setTimeout(() => {
      this.showpr()
    }, 50);
    setTimeout(() => {
      this.showpr()
    }, 50);
  }

  bkvalue(name, val) {
    this.backgrounditems.forEach(item => {
      if (item.name == name) {
        item.value = val
      }
    })
    this.resetpr()
  }
  handlebk(name) {
    this.bkmodule = {
      image: false,
      color: false,
      size: false,
      position: false,
      repeat: false,
      attachment: false,
      box: false,
      selected: null
    }
    if (name === 'color') {
      this.selectedPortal = this.componentPortal
    }
    this.bkmodule[name] = true
    this.resetpr()
  }

  handleBk(br) {
    let name = 'style'
    this.backgrounditems.forEach(item => {
      item.selected = false
      if (item.value === br.value) {
        this.backgrounditems.selected = br.value
      }
    })

    br.elm.forEach(element => {
      if (this.bordervals[name][element] == true) {
        this.bordervals[name][element] = false
      }
      this.bordervals[name][element] = true
    });
    this.resetpr()
  }
  constbk(name, val) {
    let cssname = ('background-' + name);
    this.product(cssname, val)
  }
  selectBk(val, name) {
    if (name === 'image') {
      this.backgrounditems[name] = 'url("' + val + '")';
      this.backgroundvals[name] = 'url("' + val + '")';
      this.constbk(name, ('url("' + val + '")'))
      return
    }
    if (name === 'color') {
      this.backgrounditems[name] = val;
      this.constbk(name, val)
      return
    }
    if (name === 'line-width') {

    }
    if (name === 'line-style') {

    }
    if (val == '<length-percentage>') {
      this.getpercentage('Number', 'between 1 - 1,000').then(num => {
        if (num) {
          this.css.backgrounditems[name].push(num.values.num + '%');
          this.backgroundvals[name] = (num.values.num + '%')
          this.constbk(name, (num.values.num + '%'))
          return
        }
      })
      return
    } else {
      this.backgroundvals[name] = val
      if (name != 'selected') {
        this.constbk(name, val)
      };
    }
    this.resetpr()
  }

  removeBk(name) {
    this.backgroundvals[name] = null
    this.resetpr()
  }

  handleBr(br) {
    //this.bordermodule[this.bordermodule.selected.name] = this.bordermodule.selected.val
    let vals = this.bordermodule.selected;
    let name = ''
    if (this.bordermodule.border) {
      name = ''
    }
    if (this.bordermodule.radius) {
      name = 'Radius'
    }
    if (this.bordermodule.style) {
      name = 'Style'
    }
    if (this.bordermodule.width) {
      name = 'Width'
    }
    if (this.bordermodule.color) {
      name = 'Color'
    }
    if (this.bordermodule.image) {
      name = 'Source'
    }
    if (this.borderitems[br]) {
      this.borderitems[br].selected = true
    }
    this.borderitems.forEach(borderitem => {
      borderitem.selected = false
      if (borderitem.value === br.value) {
        this.bordermodule.selected = { val: borderitem.value, name: this.bordermodule.selected.name, sides: br.name };
      }
    })

    br.elm.forEach(element => {
      if (vals.name != 'selected') {
        if (this.bordermodule.image) {

        } else {
          let cssname = ('border' + element + name);
          this.product(cssname, vals.val)
        }
      };
      if (this.bordervals[name.toLowerCase()][element.toLowerCase()] == true) {
        this.bordervals[name.toLowerCase()][element.toLowerCase()] = false
      }
      this.bordervals[name.toLowerCase()][element.toLowerCase()] = vals.val
    });
    this.resetpr()
  }

  async startFrom() {
    const modal = await this.modalCtrl.create({
      component: SettingsComponent,
    })
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      switch (data) {
        case 'scratch':
          return this.preview.css = {}
        case 'card':
          return this.preview.css = { "height": "300px", "width": "auto", "background-color": "#6c7399", "border-radius": "9%", "box-shadow": "1px 2px 2px hsl(220deg 60% 50% / 0.333), 2px 4px 4px hsl(220deg 60% 50% / 0.333), 3px 6px 6px hsl(220deg 60% 50% / 0.333)" }
        default:
          return
      }
      console.log(data)
      //this.numbers.addbutton(data)
    }
    return
  }

  selectBr(val, name) {
    console.info(val)
    if (name === 'width') {
    }
    if (name === 'radius') {
      this.bordermodule[name] = val + "%"
    }
    if (val == '<length>') {
      this.getpercentage('Number', 'between 1 - 1,000').then(num => {
        if (num) {
          this.css.linewidth.push(num.values.num);
          val = num.values.num
          this.bordermodule.selected.val = val
          this.bordervals[name] = {
            current: val,
            top: this.bordervals[name].top,
            bottom: this.bordervals[name].bottom,
            right: this.bordervals[name].right,
            left: this.bordervals[name].left,
            selected: this.bordervals[name].selected
          }
        }
      })
    }
    if (name === 'image') {
      name === 'ImageSource'
      val = ('url(' + val + ')');
      this.bordermodule.selected.val = val
      this.bordervals.image = {
        current: val,
        top: this.bordervals[name].top,
        bottom: this.bordervals[name].bottom,
        right: this.bordervals[name].right,
        left: this.bordervals[name].left,
        selected: this.bordervals[name].selected
      }
      //this.product('border', '50px solid');
      //this.product(('borderImageSource'), val);
      //returnselectBr
    }
    this.bordermodule.selected.val = val
    this.bordervals[name] = {
      current: val,
      top: this.bordervals[name].top,
      bottom: this.bordervals[name].bottom,
      right: this.bordervals[name].right,
      left: this.bordervals[name].left,
      selected: this.bordervals[name].selected
    }
    this.resetpr()
  }

  removeBr() {
    this.bordermodule.selected.sides = null
    this.resetpr()
  }
  rmBr(name) {
    this.bordervals[name].current = null
    this.resetpr()
  }
  resetBr(item: any) {
  }

  functionalproduct(element, value) {
    switch (element) {
      case 'url':
        this.product(this.mainelm, this.sanitizer.bypassSecurityTrustStyle('url(' + value + ')'))
        return
    }
  }

  copytocliptboard() {
    this.clipboard.copy(JSON.stringify(this.stylePipe.transform(this.preview)))
    this.snackBar.open('Copied to Clipboard', 'Ok !', {
      duration: 3000,
    })
  }

  downloadjson() {
    var theJSON = JSON.stringify(this.stylePipe.transform(this.preview));
    var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
    this.downloadJsonHref = uri;

    var element = document.createElement('a');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
    element.setAttribute('download', "style.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);

  }

  showcodetoggle() {
    if (this.showcode === false) {
      this.showcode = true;
    } else {
      this.showcode = false;
    }
  }

  editcode() {
    if (this.codeedit === false) {
      this.codeedit = true;
    } else {
      this.codeedit = false;
    }
  }

  submitCustom(name, value) {
    console.log(name, value)
    this.preview[this.selectedSudo][name] = value;
    this.resetpr()
  }

  deleteCustom(item) {
    console.log(item);
    this.submitCustom(this.selectedSudo, item.key)
    this.preview[this.selectedSudo][item.key] = null;
    console.log(this.preview['css'])
    this.preview = this.preview;
    this.cdr.detectChanges()
    this.resetpr()
  }

  caclpercentage(num, unit) {
    this.product(unit, (num + '%'))
  }
  lengthproduct(value, unit) {
    if (unit) {
      this.product(this.mainelm, (value + unit));
    }
  }

  contr(direction, velocity, options) {
    if (velocity) {

    }
    if (direction) {
      console.log(options)
      switch (direction) {
        case 'foward':
          this.mainelm = this.getselector(this.css.selectors[options])
          return
      }
    }
    if (options) {

    }
  }
  showcomponent(name) {
    if (name === 'length') {
      let counter = setInterval(() => {
        if (this.unitselector) {
          this.unitselector.open()
          clearInterval(counter)
        }
      }, 100);
    }
    this.component = {
      linestyle: false,
      color: false,
      length: false,
      url: false,
      image: false,
      font: false,
      percentage: false,
      fontfamily: false,
      fontstyle: false,
      fontweight: false,
      fontvariant: false,
      fontstretch: false,
      fontsize: false,
      border: false,
      background: false
    }
    this.component[name] = true
  }

  handlecomponent(comp) {
    switch (comp) {
      case '<length-percentage>':
        this.showcomponent('length')
        return
      case '<length>':
        this.showcomponent('length')
        return
      case '<final-bg-layer>':
        return
      case '<color>':
        return
      case '<image>':
        this.showcomponent('image')
        return
      case '<integer>':
        this.showcomponent('length')
        return
      case '<relative-size>':
        this.showcomponent('length')
        return
      case '<absolute-size>':
        this.showcomponent('length')
        return
      case '<line-style>':
        return
      case '<number>':
        this.showcomponent('length')
        return
      case '<percentage>':
        this.showcomponent('length')
        return
      case '<url>':
        return
      case '<font-family>':
        this.showcomponent('fontfamily')
        return
      case '<text-decoration-line>':
        return
      case '<font-style>':
        this.showcomponent('fontstyle')
        return
      case '<font-variant-css21>':
        return
      case '<font-weight>':
        this.showcomponent('fontweight')
        return
      case '<font-stretch>':
        this.showcomponent('fontstretch')
        return
      case '<font-size>':
        this.showcomponent('fontsize')
        return
      case '<line-height>':
        this.selected.values = ['normal', '<number>', '<length>', '<percentage>']
        this.showcomponent('length')
        return
      case '<font>':
        return
      case '<font-family>':
        this.showcomponent('fontfamily')
        return
      case '<display-outside>':
        return
      case '<display-inside>':
        return
      case '<display-listitem>':
        return
      case '<display-internal>':
        return
      case '<display-box>':
        return
      case '<display-legacy>':
        return
    }
  }

  removecolor(name) {
    this.color[name] = null
    this.resetpr()
  }

  async opengallery() {
    const modal = await this.modalCtrl.create({
      component: PhotoComponent,
    })
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      console.log(data)
      //this.numbers.addbutton(data)
    }
    return
  }

  async opengradient() {
    const modal = await this.modalCtrl.create({
      component: ThemeEditorComponent,
    })
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      console.log(data)
      //this.numbers.addbutton(data)
    }
    return
  }

  apply() {
    this.picked.emit(this.preview);
  }

  editStyle(styleId, styleName) {
    if (this.editKey === styleId) return;
    this.editmode = true;
    this.editKey = styleId;
    this.styleName = styleName;
  }

  async saveStyle() {
    this.stateHistory.next(7)
    if (this.editmode) return this.saveUpload();
    if (this.styleName) return this.uploadNew();
    await this.getInput("Style name", "enter numbers and letters only", "no spaces or characters").then(val => {
      this.styleName = val.value
      if (this.styleName) {
        this.uploadNew()
      }
    });
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Apply',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Delete and Close',
        role: 'destructive',
        icon: 'trash',
        handler: () => {
          console.log('Delete clicked');
        }
      }, {
        text: 'Apply and Close',
        icon: 'share',
        handler: async () => {
          console.log('Apply clicked');
          await this.updateBlock({ css: this.preview.css }).then(() => {
            this.close()
          }).catch((error) => {
            console.error(error)
          })
        }
      }, {
        text: 'Save Style',
        icon: 'cloud-upload-outline',
        handler: async () => {
          console.log('Save clicked');
          if (this.editmode) {
            this.saveUpload()
            this.stateHistory.next(8)
          } else {
            if (!this.styleName) {
              await this.getInput("Style name", "enter numbers and letters only", "no spaces or characters").then(val => {
                this.styleName = val.value
                if (this.styleName) {
                  this.uploadNew()
                }
              })
            }
          }
        }
      }, {
        text: 'Favorite',
        icon: 'heart',
        handler: () => {
          console.log('Favorite clicked');
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }

  deleteUndefined(obj) {
    for (var key in obj) {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    }
    return obj;
  }

  removeUndefined(obj) {
    if (!obj) return null;
    for (var key in obj) {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      } else {
        obj[key] = obj[key].toString().trim().replace(" ;", ';').replace(';', '')
      }
    }
    return obj;
  }

  stringToObject(str) {
    var obj = {};
    var lines = str.split('\n');

    function camelCase(str) {
      var newStr = str.replace(/-(\w)/g, function (match, p1) {
        return p1.toUpperCase();
      });
      return newStr;
    }



    for (var i = 0; i < lines.length; i++) {
      var line = lines[i];
      if (line.length > 0) {
        var keyValue = line.split(':');
        obj[camelCase(keyValue[0]).trim()] = keyValue[1];
      }
    }
    return this.removeUndefined(obj);
  }

  singleStyle(str) {
    const convertToObject = (str) => {
      var names = getNames(str);
      console.log(names)
      const newcss = replaceAll(names, str)
      const cssObj = this.stringToObject(newcss);
      console.log(cssObj)
      return cssObj;
    }

    function replaceAll(array, str) {
      for (var i = 0; i < array.length; i++) {
        str = str.replace(array[i], '');
      }
      return str;
    }

    function getNames(css) {
      var classes = [];
      var classNames = css.match(/\.([^\s]+)/g);
      if (classNames) {
        for (var i = 0; i < classNames.length; i++) {
          classes.push(classNames[i].substring(1).replace('{', ''));
        }
      }
      return classes
    }

    console.log('object ', convertToObject(str))
    return convertToObject(str);
  }

  async presentAlertPrompt() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Paste 1 CSS Style!',
      inputs: [
        {
          name: 'paragraph',
          id: 'paragraph',
          type: 'textarea',
          placeholder: '.1stylename { selector: value..};'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

            console.log('Confirm Cancel');
          }
        }, {
          text: 'Convert',
          handler: (alertData) => {
            console.log(alertData.paragraph);
            const convertedCss = this.singleStyle(alertData.paragraph);
            console.log('Confirm Ok');
            console.log(convertedCss);
            this.preview['css'] = convertedCss;
          }
        }
      ]
    });

    await alert.present()
    const { data } = await (await alert.onDidDismiss()).data;
  }

}

