import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CssService {
  bordertopstyle: string;
  borderrightstyle: string;
  borderbottomstyle: string;
  borderleftstyle: string;
  colors: string[] = [
    "Custom Color",
    "AliceBlue",
    "AntiqueWhite",
    "Aqua",
    "Aquamarine",
    "Azure",
    "Beige",
    "Bisque",
    "Black",
    "BlanchedAlmond",
    "Blue",
    "BlueViolet",
    "Brown",
    "BurlyWood",
    "CadetBlue",
    "Chartreuse",
    "Chocolate",
    "Coral",
    "CornflowerBlue",
    "Cornsilk",
    "Crimson",
    "Cyan",
    "DarkBlue",
    "DarkCyan",
    "DarkGoldenRod",
    "DarkGray",
    "DarkGrey",
    "DarkGreen",
    "DarkKhaki",
    "DarkMagenta",
    "DarkOliveGreen",
    "DarkOrange",
    "DarkOrchid",
    "DarkRed",
    "DarkSalmon",
    "DarkSeaGreen",
    "DarkSlateBlue",
    "DarkSlateGray",
    "DarkSlateGrey",
    "DarkTurquoise",
    "DarkViolet",
    "DeepPink",
    "DeepSkyBlue",
    "DimGray",
    "DimGrey",
    "DodgerBlue",
    "FireBrick",
    "FloralWhite",
    "ForestGreen",
    "Fuchsia",
    "Gainsboro",
    "GhostWhite",
    "Gold",
    "GoldenRod",
    "Gray",
    "Grey",
    "Green",
    "GreenYellow",
    "HoneyDew",
    "HotPink",
    "IndianRed",
    "Indigo",
    "Ivory",
    "Khaki",
    "Lavender",
    "LavenderBlush",
    "LawnGreen",
    "LemonChiffon",
    "LightBlue",
    "LightCoral",
    "LightCyan",
    "LightGoldenRodYellow",
    "LightGray",
    "LightGrey",
    "LightGreen",
    "LightPink",
    "LightSalmon",
    "LightSeaGreen",
    "LightSkyBlue",
    "LightSlateGray",
    "LightSlateGrey",
    "LightSteelBlue",
    "LightYellow",
    "Lime",
    "LimeGreen",
    "Linen",
    "Magenta",
    "Maroon",
    "MediumAquaMarine",
    "MediumBlue",
    "MediumOrchid",
    "MediumPurple",
    "MediumSeaGreen",
    "MediumSlateBlue",
    "MediumSpringGreen",
    "MediumTurquoise",
    "MediumVioletRed",
    "MidnightBlue",
    "MintCream",
    "MistyRose",
    "Moccasin",
    "NavajoWhite",
    "Navy",
    "OldLace",
    "Olive",
    "OliveDrab",
    "Orange",
    "OrangeRed",
    "Orchid",
    "PaleGoldenRod",
    "PaleGreen",
    "PaleTurquoise",
    "PaleVioletRed",
    "PapayaWhip",
    "PeachPuff",
    "Peru",
    "Pink",
    "Plum",
    "PowderBlue",
    "Purple",
    "RebeccaPurple",
    "Red",
    "RosyBrown",
    "RoyalBlue",
    "SaddleBrown",
    "Salmon",
    "SandyBrown",
    "SeaGreen",
    "SeaShell",
    "Sienna",
    "Silver",
    "SkyBlue",
    "SlateBlue",
    "SlateGray",
    "SlateGrey",
    "Snow",
    "SpringGreen",
    "SteelBlue",
    "Tan",
    "Teal",
    "Thistle",
    "Tomato",
    "Turquoise",
    "Violet",
    "Wheat",
    "White",
    "WhiteSmoke",
    "Yellow",
    "YellowGreen",
  ];

  selectors: any = [
    ['background', 'background'],
    ['background-attachment', 'backgroundAttachment'],
    ['background-color', 'backgroundColor'],
    ['background-image', 'backgroundImage'],
    ['background-position', 'backgroundPosition'],
    ['background-repeat', 'backgroundRepeat'],
    ['border', 'border'],
    ['border-bottom', 'borderBottom'],
    ['border-bottom-color', 'borderBottomColor'],
    ['border-bottom-style', 'borderBottomStyle'],
    ['border-bottom-width', 'borderBottomWidth'],
    ['border-color', 'borderColor'],
    [']border-image', 'borderImage'],
    ['border-image-source', 'borderImageSource'],
    ['border-image-slice', 'borderImageSlice'],
    ['border-image-repeat', 'borderImageRepeat'],
    ['border-image-width', 'borderImageWidth'],
    ['border-image-outset', 'borderImageOutset'],
    ['border-left', 'borderLeft'],
    ['border-left-color', 'borderLeftColor'],
    ['border-left-style', 'borderLeftStyle'],
    ['border-left-width', 'borderLeftWidth'],
    ['border-right', 'borderRight'],
    ['border-right-color', 'borderRightColor'],
    ['border-right-style', 'borderRightStyle'],
    ['border-right-width', 'borderRightWidth'],
    ['border-style', 'borderStyle'],
    ['border-top', 'borderTop'],
    ['border-top-color', 'borderTopColor'],
    ['border-top-style', 'borderTopStyle'],
    ['border-top-width', 'borderTopWidth'],
    ['border-width', 'borderWidth'],
    ['clear', 'clear'],
    ['clip', 'clip'],
    ['color', 'color'],
    ['cursor', 'cursor'],
    ['display', 'display'],
    ['filter', 'filter'],
    ['float', 'cssFloat'],
    ['font', 'font'],
    ['font-family', 'fontFamily'],
    ['font-size', 'fontSize'],
    ['font-variant', 'fontVariant'],
    ['font-weight', 'fontWeight'],
    ['height', 'height'],
    ['left', 'left'],
    ['letter-spacing', 'letterSpacing'],
    ['line-height', 'lineHeight'],
    ['list-style', 'listStyle'],
    ['list-style-image', 'listStyleImage'],
    ['list-style-position', 'listStylePosition'],
    ['list-style-type', 'listStyleType'],
    ['margin', 'margin'],
    ['margin-bottom', 'marginBottom'],
    ['margin-left', 'marginLeft'],
    ['margin-right', 'marginRight'],
    ['margin-top', 'marginTop'],
    ['overflow', 'overflow'],
    ['padding', 'padding'],
    ['padding-bottom', 'paddingBottom'],
    ['padding-left', 'paddingLeft'],
    ['padding-right', 'paddingRight'],
    ['padding-top', 'paddingTop'],
    ['page -break-after', 'pageBreakAfter'],
    ['page -break-before', 'pageBreakBefore'],
    ['position', 'position'],
    ['stroke-dasharray', 'strokeDasharray'],
    ['stroke-dashoffset', 'strokeDashoffset'],
    ['stroke-width', 'strokeWidth'],
    ['text-align', 'textAlign'],
    ['text-decoration', 'textDecoration'],
    ['text-indent', 'textIndent'],
    ['text-transform', 'textTransform'],
    ['top', 'top'],
    ['vertical-align', 'verticalAlign'],
    ['visibility', 'visibility'],
    ['width', 'width'],
    ['z-index', 'zIndex']
  ];

  cssselectors = {
    background: "background",
    backgroundAttachment: "background-attachment",
    backgroundColor: "background-color",
    backgroundImage: "background-image",
    backgroundPosition: "background-position",
    backgroundRepeat: "background-repeat",
    border: "border",
    borderBottom: "border-bottom",
    borderBottomColor: "border-bottom-color",
    borderBottomStyle: "border-bottom-style",
    borderBottomWidth: "border-bottom-width",
    borderColor: "border-color",
    borderImage: "border-image",
    borderImageSource: "border-image-source",
    borderImageSlice: "border-image-slice",
    borderImageRepeat: "border-image-repeat",
    borderImageWidth: "border-image-width",
    borderImageOutset: "border-image-outset",
    borderLeft: "border-left",
    borderLeftColor: "border-left-color",
    borderLeftStyle: "border-left-style",
    borderLeftWidth: "border-left-width",
    borderRight: "border-right",
    borderRightColor: "border-right-color",
    borderRightStyle: "border-right-style",
    borderRightWidth: "border-right-width",
    borderStyle: "border-style",
    borderTop: "border-top",
    borderTopColor: "border-top-color",
    borderTopStyle: "border-top-style",
    borderTopWidth: "border-top-width",
    borderWidth: "border-width",
    clear: "clear",
    clip: "clip",
    color: "color",
    cssFloat: "float",
    cursor: "cursor",
    display: "display",
    filter: "filter",
    font: "font",
    fontFamily: "font-family",
    fontSize: "font-size",
    fontVariant: "font-variant",
    fontWeight: "font-weight",
    height: "height",
    left: "left",
    letterSpacing: "letter-spacing",
    lineHeight: "line-height",
    listStyle: "list-style",
    listStyleImage: "list-style-image",
    listStylePosition: "list-style-position",
    listStyleType: "list-style-type",
    margin: "margin",
    marginBottom: "margin-bottom",
    marginLeft: "margin-left",
    marginRight: "margin-right",
    marginTop: "margin-top",
    overflow: "overflow",
    padding: "padding",
    paddingBottom: "padding-bottom",
    paddingLeft: "padding-left",
    paddingRight: "padding-right",
    paddingTop: "padding-top",
    pageBreakAfter: "page -break-after",
    pageBreakBefore: "page -break-before",
    position: "position",
    strokeDasharray: "stroke-dasharray",
    strokeDashoffset: "stroke-dashoffset",
    strokeWidth: "stroke-width",
    textAlign: "text-align",
    textDecoration: "text-decoration",
    textIndent: "text-indent",
    textTransform: "text-transform",
    top: "top",
    verticalAlign: "vertical-align",
    visibility: "visibility",
    width: "width",
    zIndex: "z-index"
  }

  units: any = [
    'cm',
    'em',
    'in',
    'mm',
    'px',
    'pt',
    'pc'
  ];

  linestyle = [
    'none',
    'hidden',
    'dotted',
    'dashed',
    'solid',
    'double',
    'groove',
    'ridge',
    'inset',
    'outset'
  ]

  linewidth = [
    '<length>', 
    'thin', 
    'medium', 
    'thick'
  ]

  absolutesize = [
    'xx-small',
    'x-small',
    'small',
    'medium',
    'large',
    'x-large',
    'xx-large',
    'xxx-large'
  ]

  relativesize = [
    'larger',
    'smaller'
  ]

  font = {
    family: [],
    height: [],
    weight: [],
    size: [],
    style: [],
    stretch: [],
    variant: {}
  }

  fontvariant = [
    '<caps>',
    '<numeric>',
    '<ligatures>',
    '<eastasian>'
  ]

  caps: ['normal', 'small-caps', 'all-small-caps', 'petite-caps', 'all-petite-caps', 'unicase', 'titling-caps']
  numeric: ['lining-nums', 'oldstyle-nums proportional-nums', 'tabular-nums diagonal-fractions', 'stacked-fractions']
  ligatures: ['common-ligatures', 'no-common-ligatures', 'discretionary-ligatures', 'no-discretionary-ligatures', 'historical-ligatures', 'no-historical-ligatures', 'contextual', 'no-contextual']
  eastasian: ['jis78', 'jis83', 'jis90', 'jis04', 'simplified', 'traditional', 'full-width', 'proportional-width']

  display = {
    inside: [],
    outside: [],
    listitem: [],
    box: [],
    internal: [],
    legacy: []
  }

  borderradius = {

  }

  components: any = [
    '<length-percentage>',
    '<length>',
    '<final-bg-layer>',
    '<color>',
    '<image>',
    '<integer>',
    'fit-content(<length-percentage>)',
    '<relative-size>',
    '<absolute-size>',
    '<line-style>',
    '<percentage>',
    '<url>',
    '<border>',
    'border',
    '<font>',
    'font',
    '<font-family>',
    '<text-decoration-line>',
    '<font-style>', 
    '<font-variant-css21>', 
    '<font-weight>', 
    '<font-stretch>', 
    '<font-size>', 
    '<line-height>', 
    '<font-family>',
    '<display-outside>',
    '<display-inside>',
    '<display-listitem>',
    '<display-internal>',
    '<display-box>',
    '<display-legacy>'
  ]

  relativeunits = [
    'em',
    'ex',
    'ch',
    'rem',
    'vw',
    'vmin',
    'vmax',
    '%'
  ];
 backgroundlayer = [
   '<bg-image>',
    '<bg-position> [ / <bg-size> ]?',
    '<repeat-style>',
    '<attachment>',
    '<box>',
    '<box>'
 ]

 background = {
  'bg-layer': { values: ['<bg-image>',
  '<bg-position> [ / <bg-size> ]?',
  '<repeat-style>',
  '<attachment>',
  '<box>',
  '<box>']}
 }
  options: any = {
    'background': { type: 'module list', max: 4, values: ['image', 'color', 'position', 'repeat', 'attachment', 'size', 'box'], multiple: true, },
    'background-attachment': { type: 'absolute', multiple: true, max: 2, values: ['fixed', 'local', 'scroll']},
    'background-color': { type: 'absolute', multiple: false, values: ['<color>']},
    'background-image': { type: 'module', multiple: true, values: ['none', '<image>']},
    'background-position': { type: 'module list', multiple: true, max: 4, one: ['left', 'center', 'right', 'top', 'bottom', '<length-percentage>'], two:  ['left', 'center', 'right', '<length-percentage>'], three: ['left', 'center', 'right', '<length-percentage>'], four: ['left', 'center', 'right', '<length-percentage>']},
    'background-repeat': { type: 'absolute', multiple: true, max: 2, one: ['repeat-x', 'repeat-y', 'repeat', 'space', 'round', 'no-repeat'], two: ['repeat', 'space', 'round', 'no-repeat']},
    'border': { type: 'module', multiple: true, values: ['radius', 'style', 'color', 'width', 'image']},
    'border-bottom': { type: 'shorthard', multiple: true,  max: 3, values: ['<line-width>', '<line-style>', '<color>']},
    'border-bottom-color': { type: 'absolute', multiple: false, values: ['<color>']},
    'border-bottom-style': { type: 'absolute', multiple: true, values: ['none', 'hidden', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset']},
    'border-bottom-width': { type: 'absolute', multiple: true, values: ['<length>', 'thin', 'medium', 'thick']},
    'border-color': { type: 'absolute', multiple: false, values: ['<color>']},
    'border-image': { type: 'module', multiple: true, values: ['initial', 'inherit']},
    'border-image-slice': { type: 'absolute', multiple: false, values: ['<length-percentage>', 'fill', 'initial', 'inherit']},
    'border-image-outset': { type: 'absolute', multiple: false, values: ['<length>', 'initial', 'inherit']},
    'border-image-repeat': { type: 'absolute', multiple: false, values: ['stretch', 'repeat', 'round', 'space', 'initial', 'inherit']},
    'border-image-width': { type: 'absolute', multiple: false, values: ['<length>', '<length-percentage>', 'auto', 'inital', '']},
    'border-left': { type: 'mixed', multiple: true, max: 3, values: ['<length>', 'thin', 'medium', 'thick', 'none', 'hidden', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset', '<color>']},
    'border-left-color': { type: 'absolute', multiple: false, values: ['<color>']},
    'border-left-style':  { type: 'absolute', multiple: false, values: ['none', 'hidden', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset']},
    'border-left-width': { type: 'absolute', multiple: true, values: ['<length>', 'thin', 'medium', 'thick']},
    'border-radius': { type: 'component', multiple: true, max: 4, one: ['<length>', '<percentage>'], two: ['<length>', '<percentage>'], three: ['<length>', '<percentage>'], four: ['<length>', '<percentage>']},
    'border-right': { type: 'mixed', multiple: true, values: ['<length>', 'thin', 'medium', 'thick', 'none', 'hidden', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset', '<color>']},
    'border-right-color': { type: 'absolute', multiple: false, values: ['<color>']},
    'border-right-style':  { type: 'absolute', multiple: false, values: ['none', 'hidden', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset']},
    'border-right-width': { type: 'absolute', multiple: true, values: ['<length>', 'thin', 'medium', 'thick']},
    'border-style':  { type: 'absolute', multiple: false, values: ['none', 'hidden', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset']},
    'border-top': { type: 'mixed', multiple: true, max: 3, values: ['<length>', 'thin', 'medium', 'thick', 'none', 'hidden', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset', '<color>']},
    'border-top-color': { type: 'absolute', multiple: false, values: ['<color>']},
    'border-top-style': { type: 'absolute', multiple: false, values: ['none', 'hidden', 'dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset']},
    'border-image-source': { type: 'absolute', values: ['url(<url>)']},
    'border-top-width': { type: 'absolute', multiple: true, values: ['<length>', 'thin', 'medium', 'thick']},
    'border-width': {type: 'mixed', values: ['thin', 'medium', 'thick', 'length'], attributes: '' },
    'clear': { type: 'absolute',  multiple: false, values: ['none', 'left', 'right', 'both', 'inline-start', 'inline-end']},
    'clip-path': { type: 'module', multiple: false, values: ['<url>', '<inset()>', '<circle()>', '<ellipse()>', '<polygon()>', '<path()>', 'border-box', 'padding-box', 'content-box', 'margin-box', 'fill-box', 'stroke-box', 'view-box']},
    'color': {type: 'color', values: this.colors},
    'cursor': { type: 'absolute', multiple: false, values: ['auto', 'default', 'none', 'context-menu', 'help', 'pointer', 'progress', 'wait', 'cell', 'crosshair', 'text', 'vertical-text', 'alias', 'copy', 'move', 'no-drop', 'not-allowed', 'e-resize', 'n-resize', 'ne-resize', 'nw-resize', 's-resize', 'se-resize', 'sw-resize', 'w-resize', 'ew-resize', 'ns-resize', 'nesw-resize', 'nwse-resize', 'col-resize', 'row-resize', 'all-scroll', 'zoom-in', 'zoom-out', 'grab', 'grabbing']},
    'display': {type: 'absolute', multiple: true, values: ['<display-outside> <display-inside>',
    '<display-listitem>',
    '<display-internal>',
    '<display-box>',
    '<display-legacy>']},
    'filter': {type: 'module', values: ['none', '<filter-function-list>']},
    'float': { type: 'absolute', multiple: true, values: ['left', 'right', 'none', 'inline-start', 'inline-end']},
    'font': { type: 'shorthard', multiple: true, values: ['<font-style>', '<font-variant-css21>', '<font-weight>', '<font-stretch>', '<font-size>', '<line-height>', '<font-family>', 'caption', 'icon', 'menu', 'message-box', 'small-caption', 'status-bar']},
    'font-family': { type: 'fontFamily', multiple: false, values: []},
    'font-size': { type: 'mixed', multiple: true, values: ['<absolute-size>', '<relative-size>', '<length>', '<percentage>']},
    'font-style': { type: 'absolute', multiple: true, max: 3, values: ['normal', 'italic', 'oblique <angle>']},
    'font-variant': { type: 'absolute', multiple:  false, values: ['font-variant', 'font-variant-caps', 'font-variant-numeric', 'font-variant-alternates', 'font-variant-ligatures', 'font-variant-east-asian']},
    'font-weight': { type: 'mixed', multiple: false, mixed:['number', 'values'], values: ['<number>', 'bold', 'lighter', 'bolder' ]},
    'font-stretch': { type: 'absolute', multiple: false, values: [ 'normal', 'ultra-condensed', 'extra-condensed', 'condensed', 'semi-condensed', 'semi-expanded', 'expanded', 'extra-expanded', 'ultra-expanded', '<percentage>']},
    'height': { type: 'mixed', multiple: true, values: ['<length>', '<percentage>', 'auto', 'max-content', 'min-content', 'fit-content(<length-percentage>)']},
    'left':  { type: 'mixed', multiple: false, values: ['<length>', '<percentage>', 'this.normal']},
    'letter-spacing': { type: 'mixed', multiple: false, values: ['<length>', 'this.normal']},
    'line-width': { type: 'mixed', multiple: false, values: ['<length>', 'thin', 'medium', 'thick']},
    'line-height': { type: 'mixed', multiple: false, values: ['normal', '<number>', '<length>', '<percentage>']},
    'list-style': { type: 'mixed', multiple: true, values: ['style.list-style-image', 'list-style-position', 'list-style-type']},
    'list-style-image': { type: 'mixed', multiple: false, values: ['none', '<url>']},
    'list-style-position': { type: 'absolute', multiple: false, values: ['inside', 'outside']},
    'list-style-type': { type: 'absolute', multiple: false, values: ['none', 'disc', 'circle', 'square', 'decimal', 'cjk-decimal', 'decimal-leading-zero', 'lower-roman', 'upper-roman', 'lower-greek', 'lower-alpha', 'lower-latin', 'upper-alpha', 'upper-latin', 'arabic-indic', 'armenian', 'bengali', 'cambodian', 'cjk-earthly-branch', 'cjk-heavenly-stem', 'cjk-ideographic', 'devanagari', 'ethiopic-numeric', 'georgian', 'gujarati', 'hebrew', 'hiragana', 'hiragana-iroha', 'japanese-formal', 'japanese-informal', 'kannada', 'katakana', 'katakana-iroha', 'khmer', 'korean-hangul-formal', 'korean-hanja-formal', 'korean-hanja-informal', 'lao', 'lower-armenian', 'malayalam', 'mongolian', 'myanmar', 'oriya', 'persian', 'simp-chinese-formal', 'simp-chinese-informal', 'tamil', 'telugu', 'thai', 'tibetan', 'trad-chinese-formal', 'trad-chinese-informal', 'upper-armenian', 'disclosure-open', 'disclosure-closed']},
    'margin': { type: 'mixed', multiple: true, values: ['<length>', '<percentage>', 'auto']},
    'margin-bottom': { type: 'mixed', multiple: false, values: ['<length>', '<percentage>', 'auto']},
    'margin-left': { type: 'mixed', multiple: false, values: ['<length>', '<percentage>', 'auto']},
    'margin-right': { type: 'mixed', multiple: false, values: ['<length>', '<percentage>', 'auto']},
    'margin-top': { type: 'mixed', multiple: false, values: ['<length>', '<percentage>', 'auto']},
    'overflow': { type: 'absolute', multiple: false, values: ['visible', 'hidden', 'clip', 'scroll', 'auto']},
    'padding': { type: 'mixed', multiple: true, max: 4, one: ['<length>', '<percentage>'], two: ['<length>', '<percentage>'], three: ['<length>', '<percentage>'], four: ['<length>', '<percentage>']},
    'padding-bottom': { type: 'mixed', multiple: false, values: ['<length>', '<percentage>']},
    'padding-left': { type: 'mixed', multiple: false, values: ['<length>', '<percentage>']},
    'padding-right': { type: 'mixed', multiple: false, values: ['<length>', '<percentage>']},
    'padding-top': { type: 'mixed', multiple: false, values: ['<length>', '<percentage>']},
    'break-after': { type: 'absolute', multiple: false, values: ['auto', 'avoid', 'always', 'all', 'avoid-page', 'page', 'left', 'right', 'recto', 'verso', 'avoid-column', 'column', 'avoid-region', 'region']},
    'break-before': { type: 'absolute', multiple: false, values: ['auto', 'avoid', 'always', 'all', 'avoid-page', 'page', 'left', 'right', 'recto', 'verso', 'avoid-column', 'column', 'avoid-region', 'region']},
    'position': {type: 'absolute', values:['relative', 'absolute', 'fixed', 'sticky']},
    'stroke-dasharray': 'strokeDasharray',
    'stroke-dashoffset': 'strokeDashoffset',
    'stroke-width': 'strokeWidth',
    'text-align': { type: 'absolute', multiple: false, values: ['start', 'end', 'left', 'right', 'center', 'justify', 'match-parent']},
    'text-decoration': { type: 'absolute', multiple: false, values: ['<text-decoration-line>', '<text-decoration-style>', '<text-decoration-color>']},
    'text-decoration-line': { type: 'absolute', multiple: true, max: 3, values: ['none', 'underline', 'overline', 'line-through', 'blink', 'spelling-error', 'grammar-error']},
    'text-decoration-style': { type: 'absolute', multiple: false, values: ['solid', 'double', 'dotted', 'dashed', 'wavy']},
    'text-decoration-color': { type: 'absolute', multiple: false, values: ['<color>']},
    'text-indent': { type: 'mixed', multiple: true, max: 3, one: ['<length>', '<percentage>'], three: ['each-line'], two: ['hanging']},
    'text-transform': { type: 'absolute', multiple: false, values: ['none', 'capitalize', 'uppercase', 'lowercase', 'full-width', 'full-size-kana']},
    'top': { type: 'mixed', multiple: false, values: ['<length>', '<percentage>', 'auto']},
    'vertical-align': { type: 'mixed', multiple: false, values: ['baseline', 'sub', 'super', 'text-top', 'text-bottom', 'middle', 'top', 'bottom', '<percentage>', '<length>']},
    'visibility': { type: 'absolute', multiple: false, values: ['visible', 'hidden', 'collapse']},
    'width': { type: 'absolute', multiple: false, values: ['auto', '<length>', '<percentage>', 'min-content', 'max-content', 'fit-content(<length-percentage>)']},
    'z-index': { type: 'absolute', multiple: true, values: ['auto', '<integer>']},
    };
  backgrounditems: { image: string[]; color: string[]; position: string[]; repeat: string[]; attachment: string[]; size: string[]; box: string[]; };
 
  constructor(
  ) {
    this.font = {
      family: this.options['font-family'].values,
      height: [],
      weight: this.options['font-weight'].values,
      size: this.options['font-size'].values,
      style: this.options['font-style'].values,
      stretch: this.options['font-stretch'].values,
      variant: {}
    }

    const borderstyle = [
      'none',
      'hidden',
      'dotted',
      'dashed',
      'solid',
      'double',
      'groove',
      'ridge',
      'inset',
      'outset',
      'inherit',
      'initial',
      'unset',
      'dotted',
    ]


    function asborderwidth(top, right, bottom, left) {
      let astop = top ? top : '';
      let asright = right ? right : null;
      let asbottom = bottom ? bottom : null;
      let asleft = left ? left : null;
      const borderwidth = astop + asright + asbottom + asleft;
      return borderwidth
    }
    /* top, right, bottom, left */
    interface borderwidth { one: number, two:  number }
    /* Global keywords */
    const borderwidth = [
      'inherit',
      'initial',
      'unset',
      'thin',
      'medium',
      'thick',
    ]

    const bordercolor = this.colors

    const bordertopstyle = borderstyle;
    const borderrightstyle = borderstyle;
    const borderbottomstyle = borderstyle;
    const borderleftstyle = borderstyle;

    const border = [borderstyle, borderwidth, bordercolor]
    const allaround = '';
    const measure = '';
    const borderradius = allaround;

    const margintop = measure
    const marginright = measure
    const marginbottom = measure
    const marginleft = measure
    const margin = allaround

    const paddingtop = measure
    const paddingright = measure
    const paddingbottom = measure
    const paddingleft = measure
    const padding = allaround

    class Height {
      number?: number;
      units?: string = 'red';
      val?: string;
    }

    /* height: [{number}, {units', 'relativeunits}] */

    function asheight(num: number, x: string, y: string) {
      let asheight = num ? num : 'auto';
      let unit = x ? x : y;
      const height = asheight + unit;
      return height
    }

    function checkselected(value) {
      if(this.selectors[value]){
        return true
      }
    }

    function createselected(elm, value, unit) {
      switch(elm) {
        case '<url>': 
          return 'url(' + value + ')'
        case '<percentage>':
          return value + unit
      }
    }

    function createlength(value, unit) {
      return value + unit
    }

    const relativeunits = [
      'em',
      'ex',
      'ch',
      'rem',
      'vw',
      'vmin',
      'vmax',
      '%'
    ];
  }

  public fonts = [
    { font: "'IBM Plex Mono'" },
    { font: "'IBM Plex Sans'" },
    { font: "'Montaga'" },
    { font: "'Titillium Web'" },
    { font: "'Muli'" },
    { font: "'Nanum Gothic'" },
    { font: "'Noto Sans HK'" },
    { font: "'Josefin Sans'" },
    { font: "'Anton'" },
    { font: "'Indie Flower'" },
    { font: "'Lobster'" },
    { font: "'Pacifico'" },
    { font: "'Noto Sans TC'" },
    { font: "'Source Code Pro'" },
    { font: "'Abril Fatface'" },
    { font: "'Exo 2'" },
    { font: "'Yanone Kaffeesatz'" },
    { font: "'Dancing Script'" },
    { font: "'Acme'" },
    { font: "'Bree Serif'" },
    { font: "'Righteous'" },
    { font: "'Signika'" },
    { font: "'Comfortaa'" },
    { font: "'Amatic SC'" },
    { font: "'Ubuntu Condensed'" },
    { font: "'Fira Sans Condensed'" },
    { font: "'Cairo'" },
    { font: "'Gloria Hallelujah'" },
    { font: "'Kodchasan'" },
    { font: "'Rajdhani'" },
    { font: "'Francois One'" },
    { font: "'Crete Round'" },
    { font: "'Permanent Marker'" },
    { font: "'Rokkitt'" },
    { font: "'Patua One'" },
    { font: "'Cuprum'" },
    { font: "'Prompt'" },
    { font: "'Courgette'" },
    { font: "'Fredoka One'" },
    { font: "'Kaushan Script'" },
    { font: "'Noto Sans SC'" },
    { font: "'Orbitron'" },
    { font: "'Cantarell'" },
    { font: "'Alfa Slab One'" },
    { font: "'Luckiest Guy'" },
    { font: "'Lobster Two'" },
    { font: "'Concert One'" },
    { font: "'Russo One'" },
    { font: "'Macondo Swash Caps'" },
    { font: "'Economica'" },
    { font: "'Staatliches'" },
    { font: "'Baloo'" },
    { font: "'Viga'" },
    { font: "'Playfair Display SC'" },
    { font: "'Sanchez'" },
    { font: "'Paytone One'" },
    { font: "'Special Elite'" },
    { font: "'Marck Script'" },
    { font: "'Lemonada'" },
    { font: "'VT323'" },
    { font: "'Merienda'" },
    { font: "'Changa'" },
    { font: "'Unica One'" },
    { font: "'Sawarabi Mincho'" },
    { font: "'Monoton'" },
    { font: "'Audiowide'" },
    { font: "'Playball'" },
    { font: "'Bangers'" },
    { font: "'Rock Salt'" },
    { font: "'Gochi Hand'" },
    { font: "'Ruda'" },
    { font: "'Yellowtail'" },
    { font: "'Sigmar One'" },
    { font: "'Architects Daughter'" },
    { font: "'Shadows Into Light Two'" },
    { font: "'Press Start 2P'" },
    { font: "'Carter One'" },
    { font: "'Oleo Script'" },
    { font: "'Fugaz One'" },
    { font: "'Fredericka the Great'" },
    { font: "'Baloo Bhai'" },
    { font: "'Mitr'" },
    { font: "'Bevan'" },
    { font: "'Black Ops One'" },
    { font: "'Knewave'" },
    { font: "'Share Tech Mono'" },
    { font: "'Cabin Sketch'" },
    { font: "'Love Ya Like A Sister'" },
    { font: "'Coming Soon'" },
    { font: "'Quantico'" },
    { font: "'Syncopate'" },
    { font: "'Leckerli One'" },
    { font: "'Shojumaru'" },
    { font: "'Berkshire Swash'" },
    { font: "'Squada One'" },
    { font: "'Jockey One'" },
    { font: "'Nanum Brush Script'" },
    { font: "'Annie Use Your Telescope'" },
    { font: "'Saira'" }
  ]

  public fonts2 = [
    'IBM Plex Mono',
    'IBM Plex Sans',
    'Montaga',
    'Titillium Web',
    'Muli',
    'Nanum Gothic',
    'Noto Sans HK',
    'Josefin Sans',
    'Anton',
    'Indie Flower',
    'Lobster',
    'Pacifico',
    'Noto Sans TC',
    'Source Code Pro',
    'Abril Fatface',
    'Exo 2',
    'Yanone Kaffeesatz',
    'Dancing Script',
    'Acme',
    'Bree Serif',
    'Righteous',
    'Signika',
    'Comfortaa',
    'Amatic SC',
    'Ubuntu Condensed',
    'Fira Sans Condensed',
    'Cairo',
    'Gloria Hallelujah',
    'Kodchasan',
    'Rajdhani',
    'Francois One',
    'Crete Round',
    'Permanent Marker',
    'Rokkitt',
    'Patua One',
    'Cuprum',
    'Prompt',
    'Courgette',
    'Fredoka One',
    'Kaushan Script',
    'Noto Sans SC',
    'Orbitron',
    'Cantarell',
    'Alfa Slab One',
    'Luckiest Guy',
    'Lobster Two',
    'Concert One',
    'Russo One',
    'Macondo Swash Caps',
    'Economica',
    'Staatliches',
    'Baloo',
    'Viga',
    'Playfair Display SC',
    'Sanchez',
    'Paytone One',
    'Special Elite',
    'Marck Script',
    'Lemonada',
    'VT323',
    'Merienda',
    'Changa',
    'Unica One',
    'Sawarabi Mincho',
    'Monoton',
    'Audiowide',
    'Playball',
    'Bangers',
    'Rock Salt',
    'Gochi Hand',
    'Ruda',
    'Yellowtail',
    'Sigmar One',
    'Architects Daughter',
    'Shadows Into Light Two',
    'Press Start 2P',
    'Carter One',
    'Oleo Script',
    'Fugaz One',
    'Fredericka the Great',
    'Baloo Bhai',
    'Mitr',
    'Bevan',
    'Black Ops One',
    'Knewave',
    'Share Tech Mono',
    'Cabin Sketch',
    'Love Ya Like A Sister',
    'Coming Soon',
    'Quantico',
    'Syncopate',
    'Leckerli One',
    'Shojumaru',
    'Berkshire Swash',
    'Squada One',
    'Jockey One',
    'Nanum Brush Script',
    'Annie Use Your Telescope',
    'Saira'
  ]

  public gradients = [
    { name: 'Grade Grade', css: 'linear-gradient(to right, #bdc3c7, #2c3e50)' },
    { name: 'Piggy Pink', css: 'linear-gradient(to right, #ee9ca7, #ffdde1)' },
    { name: 'Cool Blues', css: 'linear-gradient(to right, #2193b0, #6dd5ed)' },
    { name: 'MegaTron', css: 'linear-gradient(to right, #c6ffdd, #fbd786, #f7797d)' },
    { name: 'Moonlit Asteroid', css: 'linear-gradient(to right, #0f2027, #203a43, #2c5364)' },
    { name: 'JShine', css: 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59)' },
    { name: 'Evening Sunshine', css: 'linear-gradient(to right, #b92b27, #1565c0)' },
    { name: 'Dark Ocean', css: 'linear,-gradient(to right, #373b44, #4286f4)' },
    { name: 'Cool Sky', css: 'linear-gradient(to right, #2980b9, #6dd5fa, #ffffff)' },
    { name: 'Yoda', css: 'linear-gradient(to right, #ff0099, #493240)' },
    { name: 'Memariani', css: 'linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)' },
    { name: 'Amin', css: 'linear-gradient(to right, #8e2de2, #4a00e0)' },
    { name: 'Harvey', css: 'linear-gradient(to right, #1f4037, #99f2c8)' },
    { name: 'Neuromancer', css: 'linear-gradient(to right, #f953c6, #b91d73)' },
    { name: 'Azur Lane', css: 'linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4)' },
    { name: 'Witching Hour', css: 'linear-gradient(to right, #c31432, #240b36)' },
    { name: 'Flare', css: 'linear-gradient(to right, #f12711, #f5af19)' },
    { name: 'Metapolis', css: 'linear-gradient(to right, #659999, #f4791f)' },
    { name: 'Kyoo Pal', css: 'linear-gradient(to right, #dd3e54, #6be585)' },
    { name: 'Kye Meh', css: 'linear-gradient(to right, #8360c3, #2ebf91)' },
    { name: 'Kyoo Tah', css: 'linear-gradient(to right, #544a7d, #ffd452)' },
    { name: 'By Design', css: 'linear-gradient(to right, #009fff, #ec2f4b)' },
    { name: 'ultra Violet', css: 'linear-gradient(to right, #654ea3, #eaafc8)' },
    { name: 'Burning Orange', css: 'linear-gradient(to right, #ff416c, #ff4b2b)' },
    { name: 'Wiretap', css: 'linear-gradient(to right, #8a2387, #e94057, #f27121)' },
    { name: 'Summer Dog', css: 'linear-gradient(to right, #a8ff78, #78ffd6)' },
    { name: 'Rastafari', css: 'linear-gradient(to right, #1e9600, #fff200, #ff0000)' },
    { name: 'Sin City Red', css: 'linear-gradient(to right, #ed213a, #93291e)' },
    { name: 'citrus Peel', css: 'linear-gradient(to right, #fdc830, #f37335)' },
    { name: 'Blue Raspberry', css: 'linear-gradient(to right, #00b4db, #0083b0)' },
    { name: 'Margo', css: 'linear-gradient(to right, #ffefba, #ffffff)' },
    { name: 'Magic', css: 'linear-gradient(to right, #59c173, #a17fe0, #5d26c1)' },
    { name: 'Evening Night', css: 'linear-gradient(to right, #005aa7, #fffde4)' },
    { name: 'Vanusa', css: 'linear-gradient(to right, #da4453, #89216b)' },
    { name: 'Shifty', css: 'linear-gradient(to right, #636363, #a2ab58)' },
    { name: 'Expresso', css: 'linear-gradient(to right, #ad5389, #3c1053)' },
    { name: 'Ocean View', css: 'linear-gradient(to right, #a8c0ff, #3f2b96)' },
    { name: 'Pure Lust', css: 'linear-gradient(to right, #333333, #dd1818)' },
    { name: 'Moon Purple', css: 'linear-gradient(to right, #4e54c8, #8f94fb)' },
    { name: 'Red Sunset', css: 'linear-gradient(to right, #355c7d, #6c5b7b, #c06c84)' },
    { name: 'Shifter', css: 'linear-gradient(to right, #bc4e9c, #f80759)' },
    { name: 'Wedding Day blues', css: 'linear-gradient(to right, #40e0d0, #ff8c00, #ff0080)' },
    { name: 'Sand to blue', css: 'linear-gradient(to right, #3e5151, #decba4)' },
    { name: 'Quepal', css: 'linear-gradient(to right, #11998e, #38ef7d)' },
    { name: 'Pun Yeta', css: 'linear-gradient(to right, #108dc7, #ef8e38)' },
    { name: 'Sublime Light', css: 'linear-gradient(to right, #fc5c7d, #6a82fb)' },
    { name: 'Sublime Vivid', css: 'linear-gradient(to right, #fc466b, #3f5efb)' },
    { name: 'Bighead', css: 'linear-gradient(to right, #c94b4b, #4b134f)' },
    { name: 'Taran Tado', css: 'linear-gradient(to right, #23074d, #cc5333)' },
    { name: 'Relaxing Red', css: 'linear-gradient(to right, #fffbd5, #b20a2c)' },
    { name: 'Lawrencium', css: 'linear-gradient(to right, #0f0c29, #302b63, #24243e)' },
    { name: 'Phhappiness', css: 'linear-gradient(to right, #00b09b, #96c93d)' },
    { name: 'Delicate', css: 'linear-gradient(to right, #d3cce3, #e9e4f0)' },
    { name: 'Selenium', css: 'linear-gradient(to right, #3c3b3f, #605c3c)' },
    { name: 'Sulfur', css: 'linear-gradient(to right, #cac531, #f3f9a7)' },
    { name: 'Pink Flavour', css: 'linear-gradient(to right, #800080, #ffc0cb)' },
    { name: 'Rainbow Blue', css: 'linear-gradient(to right, #00f260, #0575e6)' },
    { name: 'Orange Fun', css: 'linear-gradient(to right, #fc4a1a, #f7b733)' },
    { name: 'Digital Water', css: 'linear-gradient(to right, #74ebd5, #acb6e5)' },
    { name: 'Lithium', css: 'linear-gradient(to right, #6d6027, #d3cbb8)' },
    { name: 'Argon', css: 'linear-gradient(to right, #03001e, #7303c0, #ec38bc, #fdeff9)' },
    { name: 'Hydrogen', css: 'linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6)' },
    { name: 'Zinc', css: 'linear-gradient(to right, #ada996, #f2f2f2, #dbdbdb, #eaeaea)' },
    { name: 'velvet Sun', css: 'linear-gradient(to right, #e1eec3, #f05053)' },
    { name: 'King Yna', css: 'linear-gradient(to right, #1a2a6c, #b21f1f, #fdbb2d)' },
    { name: 'Summer', css: 'linear-gradient(to right, #22c1c3, #fdbb2d)' },
    { name: 'Orange Coral', css: 'linear-gradient(to right, #ff9966, #ff5e62)' },
    { name: 'Purpink', css: 'linear-gradient(to right, #7f00ff, #e100ff)' },
    { name: 'Dull', css: 'linear-gradient(to right, #c9d6ff, #e2e2e2)' },
    { name: 'new blue', css: 'linear-gradient(to right, #396afc, #2948ff)' },
    { name: 'AliceBlue', css: '	#F0F8FF' },
    { name: 'AntiqueWhite', css: '#FAEBD7' },
    { name: 'Aqua', css: '#00FFFF' },
    { name: 'Aquamarine', css: '#7FFFD4' },
    { name: 'Azure', css: '#F0FFFF' },
    { name: 'Beige', css: '#F5F5DC' },
    { name: 'Bisque', css: '#FFE4C4' },
    { name: 'Black', css: '#000000' },
    { name: 'BlanchedAlmond', css: '#FFEBCD' },
    { name: 'Blue', css: '#0000FF' },
    { name: 'BlueViolet', css: '#8A2BE2' },
    { name: 'Brown', css: '#A52A2A' },
    { name: 'BurlyWood', css: '#DEB887' },
    { name: 'CadetBlue', css: '#5F9EA0' },
    { name: 'Chartreuse', css: '#7FFF00' },
    { name: 'Chocolate', css: '#D2691E' },
    { name: 'Coral', css: '#FF7F50' },
    { name: 'CornflowerBlue', css: '#6495ED' },
    { name: 'Cornsilk', css: '#FFF8DC' },
    { name: 'Crimson', css: '#DC143C' },
    { name: 'Cyan', css: '#00FFFF' },
    { name: 'DarkBlue', css: '#00008B' },
    { name: 'DarkCyan', css: '#008B8B' },
    { name: 'DarkGoldenRod', css: '#B8860B' },
    { name: 'DarkGray', css: '#A9A9A9' },
    { name: 'DarkGrey', css: '#A9A9A9' },
    { name: 'DarkGreen', css: '#006400' },
    { name: 'DarkKhaki', css: '#BDB76B' },
    { name: 'DarkMagenta', css: '#8B008B' },
    { name: 'DarkOliveGreen', css: '#556B2F' },
    { name: 'DarkOrange', css: '#FF8C00' },
    { name: 'DarkOrchid', css: '#9932CC' },
    { name: 'DarkRed', css: '#8B0000' },
    { name: 'DarkSalmon', css: '#E9967A' },
    { name: 'DarkSeaGreen', css: '#8FBC8F' },
    { name: 'DarkSlateBlue', css: '#483D8B' },
    { name: 'DarkSlateGray', css: '#2F4F4F' },
    { name: 'DarkSlateGrey', css: '#2F4F4F' },
    { name: 'DarkTurquoise', css: '#00CED1' },
    { name: 'DarkViolet', css: '#9400D3' },
    { name: 'DeepPink', css: '#FF1493' },
    { name: 'DeepSkyBlue', css: '#00BFFF' },
    { name: 'DimGray', css: '#696969' },
    { name: 'DimGrey', css: '#696969' },
    { name: 'DodgerBlue', css: '#1E90FF' },
    { name: 'FireBrick', css: '#B22222' },
    { name: 'FloralWhite', css: '#FFFAF0' },
    { name: 'ForestGreen', css: '#228B22' },
    { name: 'Fuchsia', css: '#FF00FF' },
    { name: 'Gainsboro', css: '#DCDCDC' },
    { name: 'GhostWhite', css: '#F8F8FF' },
    { name: 'Gold', css: '#FFD700' },
    { name: 'GoldenRod', css: '#DAA520' },
    { name: 'Gray', css: '#808080' },
    { name: 'Grey', css: '#808080' },
    { name: 'Green', css: '#008000' },
    { name: 'GreenYellow', css: '#ADFF2F' },
    { name: 'HoneyDew', css: '#F0FFF0' },
    { name: 'HotPink', css: '#FF69B4' },
    { name: 'IndianRed ', css: '#CD5C5C' },
    { name: 'Indigo ', css: '#4B0082' },
    { name: 'Ivory', css: '#FFFFF0' },
    { name: 'Khaki', css: '#F0E68C' },
    { name: 'Lavender', css: '#E6E6FA' },
    { name: 'LavenderBlush', css: '#FFF0F5' },
    { name: 'LawnGreen', css: '#7CFC00' },
    { name: 'LemonChiffon', css: '#FFFACD' },
    { name: 'LightBlue', css: '#ADD8E6' },
    { name: 'LightCoral', css: '#F08080' },
    { name: 'LightCyan', css: '#E0FFFF' },
    { name: 'LightGoldenRodYellow', css: '#FAFAD2' },
    { name: 'LightGray', css: '#D3D3D3' },
    { name: 'LightGrey', css: '#D3D3D3' },
    { name: 'LightGreen', css: '#90EE90' },
    { name: 'LightPink', css: '#FFB6C1' },
    { name: 'LightSalmon', css: '#FFA07A' },
    { name: 'LightSeaGreen', css: '#20B2AA' },
    { name: 'LightSkyBlue', css: '#87CEFA' },
    { name: 'LightSlateGray', css: '#778899' },
    { name: 'LightSlateGrey', css: '#778899' },
    { name: 'LightSteelBlue', css: '#B0C4DE' },
    { name: 'LightYellow', css: '#FFFFE0' },
    { name: 'Lime', css: '#00FF00' },
    { name: 'LimeGreen', css: '#32CD32' },
    { name: 'Linen', css: '#FAF0E6' },
    { name: 'Magenta', css: '#FF00FF' },
    { name: 'Maroon', css: '#800000' },
    { name: 'MediumAquaMarine', css: '#66CDAA' },
    { name: 'MediumBlue', css: '#0000CD' },
    { name: 'MediumOrchid', css: '#BA55D3' },
    { name: 'MediumPurple', css: '#9370DB' },
    { name: 'MediumSeaGreen', css: '#3CB371' },
    { name: 'MediumSlateBlue', css: '#7B68EE' },
    { name: 'MediumSpringGreen', css: '#00FA9A' },
    { name: 'MediumTurquoise', css: '#48D1CC' },
    { name: 'MediumVioletRed', css: '#C71585' },
    { name: 'MidnightBlue', css: '#191970' },
    { name: 'MintCream', css: '#F5FFFA' },
    { name: 'MistyRose', css: '#FFE4E1' },
    { name: 'Moccasin', css: '#FFE4B5' },
    { name: 'NavajoWhite', css: '#FFDEAD' },
    { name: 'Navy', css: '#000080' },
    { name: 'OldLace', css: '#FDF5E6' },
    { name: 'Olive', css: '#808000' },
    { name: 'OliveDrab', css: '#6B8E23' },
    { name: 'Orange', css: '#FFA500' },
    { name: 'OrangeRed', css: '#FF4500' },
    { name: 'Orchid', css: '#DA70D6' },
    { name: 'PaleGoldenRod', css: '#EEE8AA' },
    { name: 'PaleGreen', css: '#98FB98' },
    { name: 'PaleTurquoise', css: '#AFEEEE' },
    { name: 'PaleVioletRed', css: '#DB7093' },
    { name: 'PapayaWhip', css: '#FFEFD5' },
    { name: 'PeachPuff', css: '#FFDAB9' },
    { name: 'Peru', css: '#CD853F' },
    { name: 'Pink', css: '#FFC0CB' },
    { name: 'Plum', css: '#DDA0DD' },
    { name: 'PowderBlue', css: '#B0E0E6' },
    { name: 'Purple', css: '#800080' },
    { name: 'RebeccaPurple', css: '#663399' },
    { name: 'Red', css: '#FF0000' },
    { name: 'RosyBrown', css: '#BC8F8F' },
    { name: 'RoyalBlue', css: '#4169E1' },
    { name: 'SaddleBrown', css: '#8B4513' },
    { name: 'Salmon', css: '#FA8072' },
    { name: 'SandyBrown', css: '#F4A460' },
    { name: 'SeaGreen', css: '#2E8B57' },
    { name: 'SeaShell', css: '#FFF5EE' },
    { name: 'Sienna', css: '#A0522D' },
    { name: 'Silver', css: '#C0C0C0' },
    { name: 'SkyBlue', css: '#87CEEB' },
    { name: 'SlateBlue', css: '#6A5ACD' },
    { name: 'SlateGray', css: '#708090' },
    { name: 'SlateGrey', css: '#708090' },
    { name: 'Snow', css: '#FFFAFA' },
    { name: 'SpringGreen', css: '#00FF7F' },
    { name: 'SteelBlue', css: '#4682B4' },
    { name: 'Tan', css: '#D2B48C' },
    { name: 'Teal', css: '#008080' },
    { name: 'Thistle', css: '#D8BFD8' },
    { name: 'Tomato', css: '#FF6347' },
    { name: 'Turquoise', css: '#40E0D0' },
    { name: 'Violet', css: '#EE82EE' },
    { name: 'Wheat', css: '#F5DEB3' },
    { name: 'White', css: '#FFFFFF' },
    { name: 'WhiteSmoke', css: '#F5F5F5' },
    { name: 'Yellow', css: '#FFFF00' },
    { name: 'YellowGreen', css: '#9ACD32' }
  ]
}
