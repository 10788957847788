import { Component, OnInit, Input, ViewChild, Inject, EventEmitter, Output } from '@angular/core';
import { environment } from "../../../environments/environment";
import 'whatwg-fetch';
import { DOCUMENT } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IonContent, ModalController } from '@ionic/angular';
import { getDoc, doc, getDocs, collection, orderBy, DocumentReference, addDoc, updateDoc, DocumentData, query } from 'firebase/firestore/lite';
import { getStorage, UploadTask, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { app, auth, firestore, storage } from 'src/app/app.module';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DashService } from 'src/app/services/dash.service';
import { PageService } from 'src/app/services/page.service';
import { UserService } from 'src/app/services/user.service';
import { createApi } from 'unsplash-js';
import { Camera, CameraResultType } from '@capacitor/camera';
// ES Modules syntax
//import Unsplash from 'unsplash-js';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {
    
  requestUrl: string;
  imageUrl: any;
  searchTerm: string;
  imageData: any;
  images: Array<any> = [];
  progress: number;
  unsplash: any;
  import: boolean = true;
  segment: boolean = false;
  currentImg: any;
  @Input() demo: boolean = false;
  @Input() data: any;
  @Input() mini: boolean;
  @Input() standalone: boolean;
  @Input() mode: string;
  @Input() blockId: string;
  @Input() pageId: string;
  @Input() block: any;
  @Input() request: string;
  @Input() multiple: boolean = false;
  @Output() selected: EventEmitter<any> = new EventEmitter()
  stockimg: string = 'url(';
  searched: boolean = false;
  uploadcompleted: boolean = true;
  uploading: boolean = false;
  file: any;
  videoloading: boolean;
  item: boolean;
  photoData: any;
  user: any;
  @ViewChild(IonContent) content: IonContent;
  pageData: any;
  uploadTask: UploadTask;
  error: string;
  uploadState: { paused: boolean; running: boolean; started: boolean, completed: boolean; } = {
    paused: false,
    running: false,
    started: false,
    completed: false,
  };
  myimages: any;
  inputUrl: string = '';

    constructor(
      public snackBar: MatSnackBar,
      public router: Router,
      public global: DashService,
      public userService: UserService,
      public pageService: PageService,
      public modalCtrl: ModalController,
      private analytics: AnalyticsService,
      @Inject(DOCUMENT) private document: Document,
    ) {
      
     }

     scrollTo(_id: string) {
      let y = this.document.getElementById(_id);
      this.content.scrollToTop();

      console.log('scroll to ', y)
      if(!y || y && !y.offsetTop) return this.content.scrollToTop();
      
      //this.content.scrollToPoint(0, y.offsetTop);
  }
  
    async ngOnInit() {
      // https://source.unsplash.com/featured/?{KEYWORD}
      this.requestUrl = 'https://source.unsplash.com/featured/?';
      this.unsplash = createApi({
        accessKey: environment.unsplash
      })
      if(!auth.currentUser || auth.currentUser && !auth.currentUser.uid) return console.log('auth required. no user error');
      const pageData = (await getDoc(doc(firestore, `users/${auth.currentUser.uid}`))).data().currentpage;
      this.pageData = pageData;
      if(!auth.currentUser) return;
      
      if(this.mode === 'chat') {
        this.request = 'ombed';
      }
      if(this.blockId) {
        this.setData();
      }
      this.user = auth.currentUser ? auth.currentUser :  (await this.userService.getUser())
      this.myimages = (await getDocs(query(collection(firestore, `users/${auth.currentUser.uid}/photos`), orderBy('dateUpdated')))).docs
      console.log(this.myimages);
    }

    setData() {
    }

    toJson(value) {
      return value.json
    }

    search(){
      this.searched = true
      return this.unsplash.search.photos(this.searchTerm)
      .then(this.toJson)
      .then(json => {
        return this.images = json.results;
      }).catch(err => {
        this.global.handleErr(err)
      })
    }
    close(type: string) {
      this.analytics.logEvent(`close_${type}`)
      this.modalCtrl.dismiss().catch(err => {
        if(err) {
          this.router.navigateByUrl('/dashboard')
        }
      })
    }

    importselect(value) {
      if(this.images) {}
    }

    save() {
      console.log('saving ', this.imageData)
      this.analytics.logEvent(`addBlock_${'photo'}`);
      if(this.blockId) return this.updateBlock(this.imageData).then((value: void | "error") => {
        if(value === 'error') return;
        //this.pageService.pageIdRef.next(this.pageId);
        this.close('photo')
      })
      this.addnewBlock(this.imageData).then((value: DocumentReference | "error") => {
        if(value === 'error') return;
        //this.pageService.pageIdRef.next(this.pageId);
        this.close('photo')
      })
    }

    imporUrl() {
      console.log(this.inputUrl)
      const image = this.transformsplash({
        img: {
          full: this.inputUrl
        },
        user: {
          name: auth.currentUser.displayName
        }
      });
      this.imageUrl = this.inputUrl;
      this.currentImg = image;
    }

    async addnewBlock(data: any) {
      try {
        return addDoc(collection(firestore, `pages/${this.pageId}/blocks`), data);
      } catch(error) {
        this.snackBar.open('Something went wrong', 'Ok!', {
          duration: 3000,
          panelClass: 'warn'
        });
        return 'error'
      }
    }
  
    async updateBlock(data: any) {
      try {
        return updateDoc(doc(firestore, `pages/${this.pageId}/blocks/${this.blockId}`), data);
      } catch(error) {
        this.snackBar.open('Something went wrong', 'Ok!', {
          duration: 3000,
          panelClass: 'warn'
        });
        return 'error'
      }
    }

    transformsplash(img) {
      const ombed = {
        version: "1.0",
        type: "photo",
        url: img.urls.full ? img.urls.full : '',
        width: img.height ? img.height : 'auto',
        height: img.width ? img.width : 'auto',
        maxwidth: '100%',
        maxheight: '100%',
        description: img.alt_description,
        author_name: img.user.name ? img.user.name : null,
        author_url: img.user.portfolio_url ? img.user.portfolio_url : null,
        provider_name: "unsplash",
        provider_url: img.links.self ? img.links.self : null 
      }
      const data = {
        url: img.urls.regular,
        dateUpdated: Date.now().toString(),
        rdateUpdated: (Date.now() * -1).toString(),
        ombed: ombed,
        img: true,
        publishedBy: this.user.uid,
        updatedBy: this.user.uid ? this.user.uid : null
      }
      return data
    }

    selectImage(image, id) {
      console.log(image, id)
      this.analytics.logEvent('selectImage')
      this.currentImg = image;
      this.imageData = image;
      switch(this.request) {
        case 'close':
          return this.modalCtrl.dismiss(this.imageData);
        case 'ombed':
          return this.modalCtrl.dismiss(this.imageData);
        case 'url':
          if(this.standalone) return this.selected.emit(image.url);
          console.log('find url default option .urls.regular', image)
          return this.modalCtrl.dismiss(image.url);
        default:
          this.selected.emit(this.imageData);
          if(this.blockId) {
            updateDoc(doc(firestore, `pages/${this.pageId}/blocks/${this.blockId}`), this.imageData).then(async (value) => {
            });
          }
          return this.scrollTo('hero');
      }
    }
    select(image, index){
      const transformed = this.transformsplash(image);
      console.log('transform completed ', transformed)
      this.analytics.logEvent('selectImage')
      this.currentImg = image;
      if(this.multiple) {
        if(this.images) {
          this.images[index].selected = true;
        } else {
          this.images = []
          this.images.push(image)
        }
      } 
      this.snackBar.open('Image selected', 'Ok!', {
        duration: 1000
      });
      this.imageData = this.transformsplash(image);
      switch(this.request) {
        case 'close':
          return this.modalCtrl.dismiss(this.imageData);
        case 'ombed':
          return this.modalCtrl.dismiss(this.imageData);
        case 'url':
          if(this.standalone) return this.selected.emit(image.urls.regular);
          console.log('find url default option .urls.regular', image)
          return this.modalCtrl.dismiss(image.urls.regular);
        default:
          this.selected.emit(this.imageData);
          if(this.blockId) {
            updateDoc(doc(firestore, `pages/${this.pageId}/blocks/${this.blockId}`), this.imageData);
          }
          return this.scrollTo('hero');
      }
    }

    delect(image, index) {
      if(this.multiple) {
        this.images[index].selected = false;
      } else {
        this.currentImg = null 
      }
    }

    toggle(e) {
      this.segment = false
      this.import = e === 'true' ? true : false;
    }

    back() {
      this.segment = true
    }

    delete() {
      this.imageUrl = null;
      this.imageData = null;
    }
    upload() {
      if(this.photoData.webPath) {
        this.uploadPhoto(this.photoData.webPath, this.photoData.format);
      }
      if(this.photoData.dataUrl) {
        fetch(this.photoData.dataUrl).then(res => res.blob())
        .then(blob => {
          this.uploadPhoto(blob, this.photoData.format);
        })
      }
    }

    handleUploadState(event: string, value: boolean) {
      this.uploadState[event] = value;
    }

    uploadPhoto(file, format) {
      this.uploading = true
      this.progress = 0;
      this.uploadcompleted = false;
      let name = file.name ? file.name : ''
      let path = Date.now().toString() + '-' + name.replace(`.${format}`, '') + '.' + format;
      let imageRef = ref(storage, 'images/' + this.user.uid + '/' + path)
      this.handleUploadState('started', true);
      this.uploadTask = uploadBytesResumable(imageRef, file);
      // upload task event state listerner
      this.uploadTask.on('state_changed', (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('progress ', progress, '%');
        this.progress = (snapshot.bytesTransferred / snapshot.totalBytes);
        switch (snapshot.state) {
          case 'paused': // or 'paused'
            this.handleUploadState('paused', true)
            this.handleUploadState('running', true)
            break;
          case 'running': // or 'running'
          this.handleUploadState('running', true)
          this.handleUploadState('paused', false)
            break;
        }
      }, (error: any) => {
        switch (error.code) {
          case 'storage/unauthorized':
            let snackBarRef = this.snackBar.open('Upload failed, Acces denied please login ', 'OK!', {
              duration: 9000
            });
            break;
          case 'storage/canceled':
            this.snackBar.open('Upload Canceled, Please Try Again ', 'OK!', {
              duration: 9000
            });
            break;
          case 'storage/unknown':
            this.global.handleErr(error)
            this.snackBar.open('Unknown Error, developers have been notified', 'OK!', {
              duration: 9000
            });
            break;
        }
      }, async () => {
        let ref = (await this.uploadTask).ref.toString();
        console.log(ref)
        getDownloadURL(this.uploadTask.snapshot.ref).then(async (downloadURL) => {
          console.log('File available at', downloadURL);
          this.snackBar.open('Upload Complete', 'OK!', {
            duration: 9000
          });
          this.toggle(false);
          this.progress = null
          this.uploadcompleted = true
          this.uploading = false
          this.savetodatabase(downloadURL, ref)
          
        });
      });
    }

    async loadData() {
      this.myimages = (await getDocs(query(collection(firestore, `users/${auth.currentUser.uid}/photos`), orderBy('dateUpdated')))).docs
    }

    uploadTaskToggle(event: string) {
      switch (event) {
        case 'pause':
          this.uploadTask.pause()
        case 'cancel':
          this.uploadTask.cancel()
        case 'resume':
          this.uploadTask.resume()
      }
    }
    remove(image) {
      // To Do Remove Image from database
    }
    async savetodatabase(url, ref) {
      const ombed = {
        version: "1.0",
	      type: "photo",
        url: url,
        width: 'auto',
        height: 'auto',
        maxwidth: '100%',
        maxheight: '100%',
        author_name: this.user.displayName ? this.user.displayName : 'unknown',
        author_url: `https://www.linky.app/${this.pageData.name}`,
        provider_name: "linky.app",
        provider_url: "https://www.linky.app/" 
      }
      const data = {
        photoRef: ref,
        img: true,
        url: url,
        dateUpdated: Date.now().toString(),
        rdateUpdated: (Date.now() * -1).toString(),
        ombed: ombed,
        owner: this.user.uid,
        updatedBy: this.user.uid ? this.user.uid : null
      }
      this.imageData = data;

      if(!this.user.uid) return this.error = 'no user found';
      addDoc(collection(firestore, `users/${this.user.uid}/photos`), data).then((newDoc: DocumentReference<DocumentData>) => {
        this.analytics.logEvent('imageUploaded')
        this.global.snackBar.open('image saved', 'Ok', {duration: 3000});
        this.myimages = null;
        this.loadData();
      }).catch((error) => {
        this.error = 'unable to save file'
        this.global.handleErr(error)
      })
      /*
      console.log(this.imageData);
      if(this.blockId) {
        return this.blockRef.update(ombed)
      }
      try {
        return this.firestore.collection('pages').doc(this.pageId).collection('blocks').add(data).then(() => {
          this.global.snackBar.open('image added', 'Ok', {duration: 3000})
        })
      } catch (error) {
        this.global.handleErr(error)
      }
      */
    }
    async takePicture() {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      });

      this.uploadPhoto(image, image.format)

      // image.webPath will contain a path that can be set as an image src. 
      // You can access the original file using image.path, which can be 
      // passed to the Filesystem API to read the raw data of the image, 
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)

      /*
      this.imageUrl = image.dataUrl
      let format = image.format
      this.photoData = image 
      this.uploadPhoto(this.imageUrl, format)
      */
      // Can be set to the src of an image now
    }

    async takeVideo() {
      /*
      const vid = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos
      });
      */
      // image.webPath will contain a path that can be set as an image src. 
      // You can access the original file using image.path, which can be 
      // passed to the Filesystem API to read the raw data of the image, 
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      // var imageUrl = vid.webPath;
      // this.imageUrl = vid.webPath
      // Can be set to the src of an image now
    }
    
    handleVFiles(e) {
      this.file = e.srcElement.files[0];
      let format = this.file.type.replace("image/", '')
      
      if (this.file.size > 209715200) {
        this.error = 'file size is too big'
        let snackBarRef = this.snackBar.open('Videos must be 200 MB or less', 'OK!', {
          duration: 3000
        });
      } else {
        this.videoloading = true;
        console.log('handling file with the following atributes', this.file)
        console.log(format);
        this.uploadPhoto(this.file, format);
      }
    }
  
    uploadImg() {
      let path = Date.now().toString() + '-' + this.file.name;
      let videoRef = ref(storage, 'images/' + path);
      let me = this;
      this.uploadTask = uploadBytesResumable(videoRef, this.file);
      this.uploadTask.on('state_changed', (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('progress ', progress, '%');
        this.progress = (snapshot.bytesTransferred / snapshot.totalBytes);
        switch (snapshot.state) {
          case 'paused': // or 'paused'
            this.handleUploadState('paused', true)
            this.handleUploadState('running', true)
            break;
          case 'running': // or 'running'
          this.handleUploadState('running', true)
          this.handleUploadState('paused', false)
            break;
        }
      }, (error: any) => {
        switch (error.code) {
          case 'storage/unauthorized':
            let snackBarRef = this.snackBar.open('Upload failed, Acces denied please login ', 'OK!', {
              duration: 9000
            });
            break;
          case 'storage/canceled':
            this.snackBar.open('Upload Canceled, Please Try Again ', 'OK!', {
              duration: 9000
            });
            break;
          case 'storage/unknown':
            this.global.handleErr(error)
            this.snackBar.open('Unknown Error, developers have been notified', 'OK!', {
              duration: 9000
            });
            break;
        }
      }, async () => {
        let ref = (await this.uploadTask).ref.toString();
        console.log(ref)
        getDownloadURL(this.uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          this.snackBar.open('Upload Complete', 'OK!', {
            duration: 9000
          });
          this.imageUrl = downloadURL;
          this.item = true;
        });
      });
    }

  }
  