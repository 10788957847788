import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(
    public snackBar: MatSnackBar,
    private analytics: AnalyticsService
  ) { }

  handleErr(error) {
    this.snackBar.open(error, 'OK!', {
      duration: 9000
    });
    this.analytics.logEvent('exception', { description: error, fatal: false })
  }
}
