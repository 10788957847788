import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BorderSortPipe } from './style/pipes/border-sort.pipe';
import { StylePipe } from './style/pipes/style-pipe.pipe';
import { environment } from 'src/environments/environment';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';

import { IonicSelectableModule } from 'ionic-selectable';
import { MiniloginComponent } from './minilogin/minilogin.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyframeComponent } from './style/keyframe/keyframe.component';
import { ModifyComponent } from './style/modify/modify.component';
import { WINDOW_PROVIDERS } from './services/window-ref.service';
import { UsertoolbarComponent } from './style/usertoolbar/usertoolbar.component';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken } from "firebase/messaging";
import { getStorage } from 'firebase/storage';
import { PhotoComponent } from './style/photo.bak/photo.component';

export const app = initializeApp(environment.firebase);
export const auth = getAuth(app);
export const firestore = getFirestore(app);

export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);
export const storage = getStorage(app);

import { ColorComponent } from './style/color/color.component';
import { GradientComponent } from './style/gradient/gradient.component';
import { StyleComponent } from './style/style.component';
import { ThemeEditorComponent } from './style/theme-editor/theme-editor.component';
import { SearchPipe } from './style/pipes/search.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SettingsComponent } from './style/settings/settings.component';
import { CodeItemComponent } from './style/code-item/code-item.component';
import { getFunctions } from 'firebase/functions';

export const functions = getFunctions(app);

const pipes = [
  StylePipe,
  BorderSortPipe,
  SearchPipe
]

@NgModule({
    declarations: [
        CodeItemComponent,
        AppComponent,
        ColorComponent,
        KeyframeComponent,
        MiniloginComponent,
        StyleComponent,
        SettingsComponent,
        GradientComponent,
        PhotoComponent,
        ModifyComponent,
        ThemeEditorComponent,
        UsertoolbarComponent,
        pipes,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule.forRoot(),
        MatMenuModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        IonicSelectableModule,
        MatSnackBarModule, 
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerWhenStable:30000'}),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        StylePipe,
        BorderSortPipe,
        WINDOW_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
