import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashService {

  constructor(
    public snackBar: MatSnackBar,
    public analytics: AnalyticsService,
    ) { }

  public handleErr(error) {
    this.snackBar.open(error, 'OK!', {
      duration: 9000
    });
    this.firelitics('exception', { description: error, fatal: false })
  }

  public displayMessage(message: any, duration?: number) {
    this.snackBar.open(message, 'OK!', {
      duration: duration ?? 3000
    });
  }

  public firelitics(log: any, event) {
    const litics = this.analytics
    if (environment.production) {
      if (log && !event || event == null) {
        litics.logEvent(log, null)
      };
      if (log && event || event != null) {
        litics.logEvent(log, event)
      }
    }
  }

  public slugify(text: string) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }

  public fonts = [
    { font: "'IBM Plex Mono'" },
    { font: "'IBM Plex Sans'" },
    { font: "'Montaga'" },
    { font: "'Titillium Web'" },
    { font: "'Muli'" },
    { font: "'Nanum Gothic'" },
    { font: "'Noto Sans HK'" },
    { font: "'Josefin Sans'" },
    { font: "'Anton'" },
    { font: "'Indie Flower'" },
    { font: "'Lobster'" },
    { font: "'Pacifico'" },
    { font: "'Noto Sans TC'" },
    { font: "'Source Code Pro'" },
    { font: "'Abril Fatface'" },
    { font: "'Exo 2'" },
    { font: "'Yanone Kaffeesatz'" },
    { font: "'Dancing Script'" },
    { font: "'Acme'" },
    { font: "'Bree Serif'" },
    { font: "'Righteous'" },
    { font: "'Signika'" },
    { font: "'Comfortaa'" },
    { font: "'Amatic SC'" },
    { font: "'Ubuntu Condensed'" },
    { font: "'Fira Sans Condensed'" },
    { font: "'Cairo'" },
    { font: "'Gloria Hallelujah'" },
    { font: "'Kodchasan'" },
    { font: "'Rajdhani'" },
    { font: "'Francois One'" },
    { font: "'Crete Round'" },
    { font: "'Permanent Marker'" },
    { font: "'Rokkitt'" },
    { font: "'Patua One'" },
    { font: "'Cuprum'" },
    { font: "'Prompt'" },
    { font: "'Courgette'" },
    { font: "'Fredoka One'" },
    { font: "'Kaushan Script'" },
    { font: "'Noto Sans SC'" },
    { font: "'Orbitron'" },
    { font: "'Cantarell'" },
    { font: "'Alfa Slab One'" },
    { font: "'Luckiest Guy'" },
    { font: "'Lobster Two'" },
    { font: "'Concert One'" },
    { font: "'Russo One'" },
    { font: "'Macondo Swash Caps'" },
    { font: "'Economica'" },
    { font: "'Staatliches'" },
    { font: "'Baloo'" },
    { font: "'Viga'" },
    { font: "'Playfair Display SC'" },
    { font: "'Sanchez'" },
    { font: "'Paytone One'" },
    { font: "'Special Elite'" },
    { font: "'Marck Script'" },
    { font: "'Lemonada'" },
    { font: "'VT323'" },
    { font: "'Merienda'" },
    { font: "'Changa'" },
    { font: "'Unica One'" },
    { font: "'Sawarabi Mincho'" },
    { font: "'Monoton'" },
    { font: "'Audiowide'" },
    { font: "'Playball'" },
    { font: "'Bangers'" },
    { font: "'Rock Salt'" },
    { font: "'Gochi Hand'" },
    { font: "'Ruda'" },
    { font: "'Yellowtail'" },
    { font: "'Sigmar One'" },
    { font: "'Architects Daughter'" },
    { font: "'Shadows Into Light Two'" },
    { font: "'Press Start 2P'" },
    { font: "'Carter One'" },
    { font: "'Oleo Script'" },
    { font: "'Fugaz One'" },
    { font: "'Fredericka the Great'" },
    { font: "'Baloo Bhai'" },
    { font: "'Mitr'" },
    { font: "'Bevan'" },
    { font: "'Black Ops One'" },
    { font: "'Knewave'" },
    { font: "'Share Tech Mono'" },
    { font: "'Cabin Sketch'" },
    { font: "'Love Ya Like A Sister'" },
    { font: "'Coming Soon'" },
    { font: "'Quantico'" },
    { font: "'Syncopate'" },
    { font: "'Leckerli One'" },
    { font: "'Shojumaru'" },
    { font: "'Berkshire Swash'" },
    { font: "'Squada One'" },
    { font: "'Jockey One'" },
    { font: "'Nanum Brush Script'" },
    { font: "'Annie Use Your Telescope'" },
    { font: "'Saira'" }
  ]

  public fonts2 = [
    'IBM Plex Mono',
    'IBM Plex Sans',
    'Montaga',
    'Titillium Web',
    'Muli',
    'Nanum Gothic',
    'Noto Sans HK',
    'Josefin Sans',
    'Anton',
    'Indie Flower',
    'Lobster',
    'Pacifico',
    'Noto Sans TC',
    'Source Code Pro',
    'Abril Fatface',
    'Exo 2',
    'Yanone Kaffeesatz',
    'Dancing Script',
    'Acme',
    'Bree Serif',
    'Righteous',
    'Signika',
    'Comfortaa',
    'Amatic SC',
    'Ubuntu Condensed',
    'Fira Sans Condensed',
    'Cairo',
    'Gloria Hallelujah',
    'Kodchasan',
    'Rajdhani',
    'Francois One',
    'Crete Round',
    'Permanent Marker',
    'Rokkitt',
    'Patua One',
    'Cuprum',
    'Prompt',
    'Courgette',
    'Fredoka One',
    'Kaushan Script',
    'Noto Sans SC',
    'Orbitron',
    'Cantarell',
    'Alfa Slab One',
    'Luckiest Guy',
    'Lobster Two',
    'Concert One',
    'Russo One',
    'Macondo Swash Caps',
    'Economica',
    'Staatliches',
    'Baloo',
    'Viga',
    'Playfair Display SC',
    'Sanchez',
    'Paytone One',
    'Special Elite',
    'Marck Script',
    'Lemonada',
    'VT323',
    'Merienda',
    'Changa',
    'Unica One',
    'Sawarabi Mincho',
    'Monoton',
    'Audiowide',
    'Playball',
    'Bangers',
    'Rock Salt',
    'Gochi Hand',
    'Ruda',
    'Yellowtail',
    'Sigmar One',
    'Architects Daughter',
    'Shadows Into Light Two',
    'Press Start 2P',
    'Carter One',
    'Oleo Script',
    'Fugaz One',
    'Fredericka the Great',
    'Baloo Bhai',
    'Mitr',
    'Bevan',
    'Black Ops One',
    'Knewave',
    'Share Tech Mono',
    'Cabin Sketch',
    'Love Ya Like A Sister',
    'Coming Soon',
    'Quantico',
    'Syncopate',
    'Leckerli One',
    'Shojumaru',
    'Berkshire Swash',
    'Squada One',
    'Jockey One',
    'Nanum Brush Script',
    'Annie Use Your Telescope',
    'Saira'
  ]

  public gradients = [
    { name: 'Grade Grade', css: 'linear-gradient(to right, #bdc3c7, #2c3e50)' },
    { name: 'Piggy Pink', css: 'linear-gradient(to right, #ee9ca7, #ffdde1)' },
    { name: 'Cool Blues', css: 'linear-gradient(to right, #2193b0, #6dd5ed)' },
    { name: 'MegaTron', css: 'linear-gradient(to right, #c6ffdd, #fbd786, #f7797d)' },
    { name: 'Moonlit Asteroid', css: 'linear-gradient(to right, #0f2027, #203a43, #2c5364)' },
    { name: 'JShine', css: 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59)' },
    { name: 'Evening Sunshine', css: 'linear-gradient(to right, #b92b27, #1565c0)' },
    { name: 'Dark Ocean', css: 'linear,-gradient(to right, #373b44, #4286f4)' },
    { name: 'Cool Sky', css: 'linear-gradient(to right, #2980b9, #6dd5fa, #ffffff)' },
    { name: 'Yoda', css: 'linear-gradient(to right, #ff0099, #493240)' },
    { name: 'Memariani', css: 'linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)' },
    { name: 'Amin', css: 'linear-gradient(to right, #8e2de2, #4a00e0)' },
    { name: 'Harvey', css: 'linear-gradient(to right, #1f4037, #99f2c8)' },
    { name: 'Neuromancer', css: 'linear-gradient(to right, #f953c6, #b91d73)' },
    { name: 'Azur Lane', css: 'linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4)' },
    { name: 'Witching Hour', css: 'linear-gradient(to right, #c31432, #240b36)' },
    { name: 'Flare', css: 'linear-gradient(to right, #f12711, #f5af19)' },
    { name: 'Metapolis', css: 'linear-gradient(to right, #659999, #f4791f)' },
    { name: 'Kyoo Pal', css: 'linear-gradient(to right, #dd3e54, #6be585)' },
    { name: 'Kye Meh', css: 'linear-gradient(to right, #8360c3, #2ebf91)' },
    { name: 'Kyoo Tah', css: 'linear-gradient(to right, #544a7d, #ffd452)' },
    { name: 'By Design', css: 'linear-gradient(to right, #009fff, #ec2f4b)' },
    { name: 'ultra Violet', css: 'linear-gradient(to right, #654ea3, #eaafc8)' },
    { name: 'Burning Orange', css: 'linear-gradient(to right, #ff416c, #ff4b2b)' },
    { name: 'Wiretap', css: 'linear-gradient(to right, #8a2387, #e94057, #f27121)' },
    { name: 'Summer Dog', css: 'linear-gradient(to right, #a8ff78, #78ffd6)' },
    { name: 'Rastafari', css: 'linear-gradient(to right, #1e9600, #fff200, #ff0000)' },
    { name: 'Sin City Red', css: 'linear-gradient(to right, #ed213a, #93291e)' },
    { name: 'citrus Peel', css: 'linear-gradient(to right, #fdc830, #f37335)' },
    { name: 'Blue Raspberry', css: 'linear-gradient(to right, #00b4db, #0083b0)' },
    { name: 'Margo', css: 'linear-gradient(to right, #ffefba, #ffffff)' },
    { name: 'Magic', css: 'linear-gradient(to right, #59c173, #a17fe0, #5d26c1)' },
    { name: 'Evening Night', css: 'linear-gradient(to right, #005aa7, #fffde4)' },
    { name: 'Vanusa', css: 'linear-gradient(to right, #da4453, #89216b)' },
    { name: 'Shifty', css: 'linear-gradient(to right, #636363, #a2ab58)' },
    { name: 'Expresso', css: 'linear-gradient(to right, #ad5389, #3c1053)' },
    { name: 'Ocean View', css: 'linear-gradient(to right, #a8c0ff, #3f2b96)' },
    { name: 'Pure Lust', css: 'linear-gradient(to right, #333333, #dd1818)' },
    { name: 'Moon Purple', css: 'linear-gradient(to right, #4e54c8, #8f94fb)' },
    { name: 'Red Sunset', css: 'linear-gradient(to right, #355c7d, #6c5b7b, #c06c84)' },
    { name: 'Shifter', css: 'linear-gradient(to right, #bc4e9c, #f80759)' },
    { name: 'Wedding Day blues', css: 'linear-gradient(to right, #40e0d0, #ff8c00, #ff0080)' },
    { name: 'Sand to blue', css: 'linear-gradient(to right, #3e5151, #decba4)' },
    { name: 'Quepal', css: 'linear-gradient(to right, #11998e, #38ef7d)' },
    { name: 'Pun Yeta', css: 'linear-gradient(to right, #108dc7, #ef8e38)' },
    { name: 'Sublime Light', css: 'linear-gradient(to right, #fc5c7d, #6a82fb)' },
    { name: 'Sublime Vivid', css: 'linear-gradient(to right, #fc466b, #3f5efb)' },
    { name: 'Bighead', css: 'linear-gradient(to right, #c94b4b, #4b134f)' },
    { name: 'Taran Tado', css: 'linear-gradient(to right, #23074d, #cc5333)' },
    { name: 'Relaxing Red', css: 'linear-gradient(to right, #fffbd5, #b20a2c)' },
    { name: 'Lawrencium', css: 'linear-gradient(to right, #0f0c29, #302b63, #24243e)' },
    { name: 'Phhappiness', css: 'linear-gradient(to right, #00b09b, #96c93d)' },
    { name: 'Delicate', css: 'linear-gradient(to right, #d3cce3, #e9e4f0)' },
    { name: 'Selenium', css: 'linear-gradient(to right, #3c3b3f, #605c3c)' },
    { name: 'Sulfur', css: 'linear-gradient(to right, #cac531, #f3f9a7)' },
    { name: 'Pink Flavour', css: 'linear-gradient(to right, #800080, #ffc0cb)' },
    { name: 'Rainbow Blue', css: 'linear-gradient(to right, #00f260, #0575e6)' },
    { name: 'Orange Fun', css: 'linear-gradient(to right, #fc4a1a, #f7b733)' },
    { name: 'Digital Water', css: 'linear-gradient(to right, #74ebd5, #acb6e5)' },
    { name: 'Lithium', css: 'linear-gradient(to right, #6d6027, #d3cbb8)' },
    { name: 'Argon', css: 'linear-gradient(to right, #03001e, #7303c0, #ec38bc, #fdeff9)' },
    { name: 'Hydrogen', css: 'linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6)' },
    { name: 'Zinc', css: 'linear-gradient(to right, #ada996, #f2f2f2, #dbdbdb, #eaeaea)' },
    { name: 'velvet Sun', css: 'linear-gradient(to right, #e1eec3, #f05053)' },
    { name: 'King Yna', css: 'linear-gradient(to right, #1a2a6c, #b21f1f, #fdbb2d)' },
    { name: 'Summer', css: 'linear-gradient(to right, #22c1c3, #fdbb2d)' },
    { name: 'Orange Coral', css: 'linear-gradient(to right, #ff9966, #ff5e62)' },
    { name: 'Purpink', css: 'linear-gradient(to right, #7f00ff, #e100ff)' },
    { name: 'Dull', css: 'linear-gradient(to right, #c9d6ff, #e2e2e2)' },
    { name: 'new blue', css: 'linear-gradient(to right, #396afc, #2948ff)' },
    { name: 'AliceBlue', css: '	#F0F8FF' },
    { name: 'AntiqueWhite', css: '#FAEBD7' },
    { name: 'Aqua', css: '#00FFFF' },
    { name: 'Aquamarine', css: '#7FFFD4' },
    { name: 'Azure', css: '#F0FFFF' },
    { name: 'Beige', css: '#F5F5DC' },
    { name: 'Bisque', css: '#FFE4C4' },
    { name: 'Black', css: '#000000' },
    { name: 'BlanchedAlmond', css: '#FFEBCD' },
    { name: 'Blue', css: '#0000FF' },
    { name: 'BlueViolet', css: '#8A2BE2' },
    { name: 'Brown', css: '#A52A2A' },
    { name: 'BurlyWood', css: '#DEB887' },
    { name: 'CadetBlue', css: '#5F9EA0' },
    { name: 'Chartreuse', css: '#7FFF00' },
    { name: 'Chocolate', css: '#D2691E' },
    { name: 'Coral', css: '#FF7F50' },
    { name: 'CornflowerBlue', css: '#6495ED' },
    { name: 'Cornsilk', css: '#FFF8DC' },
    { name: 'Crimson', css: '#DC143C' },
    { name: 'Cyan', css: '#00FFFF' },
    { name: 'DarkBlue', css: '#00008B' },
    { name: 'DarkCyan', css: '#008B8B' },
    { name: 'DarkGoldenRod', css: '#B8860B' },
    { name: 'DarkGray', css: '#A9A9A9' },
    { name: 'DarkGrey', css: '#A9A9A9' },
    { name: 'DarkGreen', css: '#006400' },
    { name: 'DarkKhaki', css: '#BDB76B' },
    { name: 'DarkMagenta', css: '#8B008B' },
    { name: 'DarkOliveGreen', css: '#556B2F' },
    { name: 'DarkOrange', css: '#FF8C00' },
    { name: 'DarkOrchid', css: '#9932CC' },
    { name: 'DarkRed', css: '#8B0000' },
    { name: 'DarkSalmon', css: '#E9967A' },
    { name: 'DarkSeaGreen', css: '#8FBC8F' },
    { name: 'DarkSlateBlue', css: '#483D8B' },
    { name: 'DarkSlateGray', css: '#2F4F4F' },
    { name: 'DarkSlateGrey', css: '#2F4F4F' },
    { name: 'DarkTurquoise', css: '#00CED1' },
    { name: 'DarkViolet', css: '#9400D3' },
    { name: 'DeepPink', css: '#FF1493' },
    { name: 'DeepSkyBlue', css: '#00BFFF' },
    { name: 'DimGray', css: '#696969' },
    { name: 'DimGrey', css: '#696969' },
    { name: 'DodgerBlue', css: '#1E90FF' },
    { name: 'FireBrick', css: '#B22222' },
    { name: 'FloralWhite', css: '#FFFAF0' },
    { name: 'ForestGreen', css: '#228B22' },
    { name: 'Fuchsia', css: '#FF00FF' },
    { name: 'Gainsboro', css: '#DCDCDC' },
    { name: 'GhostWhite', css: '#F8F8FF' },
    { name: 'Gold', css: '#FFD700' },
    { name: 'GoldenRod', css: '#DAA520' },
    { name: 'Gray', css: '#808080' },
    { name: 'Grey', css: '#808080' },
    { name: 'Green', css: '#008000' },
    { name: 'GreenYellow', css: '#ADFF2F' },
    { name: 'HoneyDew', css: '#F0FFF0' },
    { name: 'HotPink', css: '#FF69B4' },
    { name: 'IndianRed ', css: '#CD5C5C' },
    { name: 'Indigo ', css: '#4B0082' },
    { name: 'Ivory', css: '#FFFFF0' },
    { name: 'Khaki', css: '#F0E68C' },
    { name: 'Lavender', css: '#E6E6FA' },
    { name: 'LavenderBlush', css: '#FFF0F5' },
    { name: 'LawnGreen', css: '#7CFC00' },
    { name: 'LemonChiffon', css: '#FFFACD' },
    { name: 'LightBlue', css: '#ADD8E6' },
    { name: 'LightCoral', css: '#F08080' },
    { name: 'LightCyan', css: '#E0FFFF' },
    { name: 'LightGoldenRodYellow', css: '#FAFAD2' },
    { name: 'LightGray', css: '#D3D3D3' },
    { name: 'LightGrey', css: '#D3D3D3' },
    { name: 'LightGreen', css: '#90EE90' },
    { name: 'LightPink', css: '#FFB6C1' },
    { name: 'LightSalmon', css: '#FFA07A' },
    { name: 'LightSeaGreen', css: '#20B2AA' },
    { name: 'LightSkyBlue', css: '#87CEFA' },
    { name: 'LightSlateGray', css: '#778899' },
    { name: 'LightSlateGrey', css: '#778899' },
    { name: 'LightSteelBlue', css: '#B0C4DE' },
    { name: 'LightYellow', css: '#FFFFE0' },
    { name: 'Lime', css: '#00FF00' },
    { name: 'LimeGreen', css: '#32CD32' },
    { name: 'Linen', css: '#FAF0E6' },
    { name: 'Magenta', css: '#FF00FF' },
    { name: 'Maroon', css: '#800000' },
    { name: 'MediumAquaMarine', css: '#66CDAA' },
    { name: 'MediumBlue', css: '#0000CD' },
    { name: 'MediumOrchid', css: '#BA55D3' },
    { name: 'MediumPurple', css: '#9370DB' },
    { name: 'MediumSeaGreen', css: '#3CB371' },
    { name: 'MediumSlateBlue', css: '#7B68EE' },
    { name: 'MediumSpringGreen', css: '#00FA9A' },
    { name: 'MediumTurquoise', css: '#48D1CC' },
    { name: 'MediumVioletRed', css: '#C71585' },
    { name: 'MidnightBlue', css: '#191970' },
    { name: 'MintCream', css: '#F5FFFA' },
    { name: 'MistyRose', css: '#FFE4E1' },
    { name: 'Moccasin', css: '#FFE4B5' },
    { name: 'NavajoWhite', css: '#FFDEAD' },
    { name: 'Navy', css: '#000080' },
    { name: 'OldLace', css: '#FDF5E6' },
    { name: 'Olive', css: '#808000' },
    { name: 'OliveDrab', css: '#6B8E23' },
    { name: 'Orange', css: '#FFA500' },
    { name: 'OrangeRed', css: '#FF4500' },
    { name: 'Orchid', css: '#DA70D6' },
    { name: 'PaleGoldenRod', css: '#EEE8AA' },
    { name: 'PaleGreen', css: '#98FB98' },
    { name: 'PaleTurquoise', css: '#AFEEEE' },
    { name: 'PaleVioletRed', css: '#DB7093' },
    { name: 'PapayaWhip', css: '#FFEFD5' },
    { name: 'PeachPuff', css: '#FFDAB9' },
    { name: 'Peru', css: '#CD853F' },
    { name: 'Pink', css: '#FFC0CB' },
    { name: 'Plum', css: '#DDA0DD' },
    { name: 'PowderBlue', css: '#B0E0E6' },
    { name: 'Purple', css: '#800080' },
    { name: 'RebeccaPurple', css: '#663399' },
    { name: 'Red', css: '#FF0000' },
    { name: 'RosyBrown', css: '#BC8F8F' },
    { name: 'RoyalBlue', css: '#4169E1' },
    { name: 'SaddleBrown', css: '#8B4513' },
    { name: 'Salmon', css: '#FA8072' },
    { name: 'SandyBrown', css: '#F4A460' },
    { name: 'SeaGreen', css: '#2E8B57' },
    { name: 'SeaShell', css: '#FFF5EE' },
    { name: 'Sienna', css: '#A0522D' },
    { name: 'Silver', css: '#C0C0C0' },
    { name: 'SkyBlue', css: '#87CEEB' },
    { name: 'SlateBlue', css: '#6A5ACD' },
    { name: 'SlateGray', css: '#708090' },
    { name: 'SlateGrey', css: '#708090' },
    { name: 'Snow', css: '#FFFAFA' },
    { name: 'SpringGreen', css: '#00FF7F' },
    { name: 'SteelBlue', css: '#4682B4' },
    { name: 'Tan', css: '#D2B48C' },
    { name: 'Teal', css: '#008080' },
    { name: 'Thistle', css: '#D8BFD8' },
    { name: 'Tomato', css: '#FF6347' },
    { name: 'Turquoise', css: '#40E0D0' },
    { name: 'Violet', css: '#EE82EE' },
    { name: 'Wheat', css: '#F5DEB3' },
    { name: 'White', css: '#FFFFFF' },
    { name: 'WhiteSmoke', css: '#F5F5F5' },
    { name: 'Yellow', css: '#FFFF00' },
    { name: 'YellowGreen', css: '#9ACD32' }
  ]

}
