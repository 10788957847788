import { Injectable, OnInit } from '@angular/core';
import { addDoc, collection, deleteDoc, doc, DocumentData, DocumentSnapshot, getDoc, getDocs, query, QuerySnapshot, setDoc, updateDoc, where } from 'firebase/firestore/lite';
import { BehaviorSubject, Observable } from 'rxjs';
import { auth, firestore } from 'src/app/app.module';
import { UserService } from './user.service';

export class LinkyForm {
  buttonname: string;
  description: string;
  frame_color: string;
  pageId: string;
  requireemail: boolean;
  requirename: boolean;
  requirephone: boolean;
  requirepopup: boolean;
  showemail: boolean;
  showname: boolean;
  showphone: boolean;
  showpopup: boolean;
}

interface LinkyPage {
  created: string;
  updated: string;
  cover: string;
  title: string;
  img: string;
  url: string;
  video: any;
  owner: string;
  key: string;
  style: string;
  published: boolean;
  form: LinkyForm;
}

@Injectable({
  providedIn: 'root'
})
export class PageService implements OnInit {
  selectedPage: any;
  pageid: string;
  blocksRef: string;
  dashboardRef: BehaviorSubject<string> = new BehaviorSubject(null);
  contentRef: BehaviorSubject<string> = new BehaviorSubject(null);
  pageIdRef: BehaviorSubject<string> = new BehaviorSubject(null);
  pageRef: BehaviorSubject<any> = new BehaviorSubject(null);
  blocks: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  block_ids_ref: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  content: any;
  num: number = 0;
;

  constructor(
    private userService: UserService,
    ) { 
      this.pageIdRef.subscribe(async (pageId) => {
        console.log('got pageId ', pageId, 'count is ', this.num);
        this.pageid = pageId;
        this.dashboardRef.next(this.pageid);
        if(pageId == null) return;
        /*
        if(this.num > 1) return this.num = 0;
        this.num =+ 1;
        */
        this.blocksRef = `pages/${pageId}/blocks`
        const fetchPromise = await Promise.all([
          (getDocs(collection(firestore, this.blocksRef))),
          (getDoc(doc(firestore, `pages/${pageId}`))) 
        ]).then(async (value: [QuerySnapshot<DocumentData>, DocumentSnapshot<DocumentData>]) => {
          this.content = value[1].data();
          this.contentRef.next(this.content);
          if(!value[1].exists) return;
          this.block_ids_ref.next(value[1].data().block_ids);
          this.blocks.next(value[0].docs.map((value) => { return value.data() }))
        })
      });
  }
  ngOnInit() {
  }

  async setData() {
    if(!(await auth.currentUser)) return null
    const userData = await (await getDoc(doc(firestore, `users/${auth.currentUser.uid}`))).data()
    this.selectedPage = userData.currentpage;
    this.pageid = this.selectedPage.uid;
    return this.selectedPage;
  }

  async getPage() {
    if(!this.selectedPage) return await this.ngOnInit()
    return this.selectedPage
  }

  async getPageRef() {
    await this.ngOnInit()
    return getDoc(doc(firestore, this.blocksRef));
  }

  async getBlockRef(name: string): Promise<any> {
    if(!this.selectedPage) return await this.ngOnInit();
    return `pages/${this.pageid}/${name}`;
  }

  async getPageFromUrl(url) {
    if(!this.pageid) this.ngOnInit();
    return await (await getDocs(query(collection(firestore, 'pages'), where('url', '==', url)))).docs[0];
  }

  async getBlockDoc(id) {
    if(!this.pageid) this.ngOnInit();
    return await (await getDoc(doc(firestore, `pages/${this.pageid}/blocks/${id}`))).data();
  }

  async addBlock(block) {
    await addDoc(collection(firestore, `pages/${this.pageid}/blocks`), block).then((value) => { return updateDoc(doc(firestore, value.path), {'key': value.id }) });
  }

  getButton(key) {
    return getDoc(doc(firestore, `pages/${this.pageid}/blocks/${key}`));
  }

  updateBlock(key, block) {
    return updateDoc(doc(firestore, `pages/${this.pageid}/blocks/${key}`), block);
  }

  removeBlock(key) {
    return deleteDoc(doc(firestore, `pages/${this.pageid}/blocks/${key}`));
  }

  async updateBlocks(pageId, blockId) {
    const snapshot = await getDoc(doc(firestore, `pages/${pageId}/blocks/${blockId}`));
    if (!snapshot.exists) {
      console.error('Block not found');
      return;
    }
    const data = snapshot.data();
    if (!data.block_ids || data.block_ids.length <= 0) {
      data.block_ids.slides = [];
    }
    // Add the newly created slide ID to the list of slides
    data.block_ids.push(blockId);
    this.block_ids_ref.next(data.block_ids);
    updateDoc(doc(firestore, `pages/${pageId}/blocks/${blockId}`), data);
  }

  async getBlock(pageId, blockId) {
    if(!pageId || !blockId) return;
    return await (await getDoc(doc(firestore, `pages/${pageId}/blocks/${blockId}`))).data();
  }

  async moveBlock(pageId, from, to) {
    const snapshot = await getDoc(doc(firestore, `pages/${pageId}`))
    if (!snapshot.exists) {
      console.error('Block not found');
      return;
    }
    const data = snapshot.data();
    if (!data || data.block_ids.length <= 0) {
      console.error('No slides to move');
      return;
    }
    data.block_ids.splice(to, 0, ...data.block_ids.splice(from, 1));
    setDoc(doc(firestore, `pages/${pageId}`), data, { merge: true });
  }
}
