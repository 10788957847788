import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { KeyframeComponent } from './style/keyframe/keyframe.component';
import { PhotoComponent } from './style/photo.bak/photo.component';
import { StyleComponent } from './style/style.component';

const routes: Routes = [
  {
    path: '',
    component: StyleComponent
  },
  {
    path: '',
    component: PhotoComponent,
  },
  {
    path: 'animate',
    component: KeyframeComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
