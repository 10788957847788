import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Inject, Input, ViewChild } from '@angular/core';
import { Component, ElementRef, OnInit } from '@angular/core';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-keyframe',
  templateUrl: './keyframe.component.html',
  styleUrls: ['./keyframe.component.scss'],
})
export class KeyframeComponent implements OnInit, AfterViewInit {
  @ViewChild('styleContainer') styleElement: ElementRef;
  @ViewChild(IonContent) content: IonContent;
  @Input() storedFrames: Array<any> = [];
  customValueName: any;
  number: number;
  values = [
    "from",
    "to",
    "percentage"
  ]
  testData = `@-webkit-keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }`

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {

  }

  ngOnInit() {
    let style = this.document.createElement('style')
    style.textContent = this.testData
    this.document.head.appendChild(style);
  }

  ngAfterViewInit() {
    
  }

  buildStyle() {

  }

  printStyle() {

  }

  addFrame() {

  }

  removeFrame(index: string) {

  }
}
