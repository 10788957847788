import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'code-item',
  templateUrl: './code-item.component.html',
  styleUrls: ['./code-item.component.scss'],
})
export class CodeItemComponent implements OnInit, OnDestroy  {
  @Input() item: any;
  @Input() index: number;
  @Input() selected: number;

  @Output() onChange: EventEmitter<any> = new EventEmitter()
  @Output() onUpdate: EventEmitter<any> = new EventEmitter()
  @Output() onSelect: EventEmitter<number> = new EventEmitter()

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnDestroy(): void {
    this.item = null;
    this.cdr.detectChanges()
  }

  ngOnInit() {}

  submitCustom(key, value) {
    this.item.key = key
    this.item.value = value
    this.onUpdate.next(this.item)
  }

  selectItem() {
    this.selected = this.index
    this.onSelect.next(this.index)
  }

  deleteCustom() {
    let ref = this.item
    ref.value = null;
    this.onUpdate.next(ref)
    this.item = null
    //this.onChange.next({code: 200, message: null, item: ref})
    //this.ngOnDestroy();
  }
}
