import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgxJoystickComponent } from 'ngx-joystick';
import { JoystickManagerOptions, JoystickOutputData } from 'nipplejs';
import { CssService } from '../../services/css.service';

@Component({
  selector: 'app-gradient',
  templateUrl: './gradient.component.html',
  styleUrls: ['./gradient.component.scss'],
})
export class GradientComponent implements OnInit {
  images: any;
  @Output() selected: EventEmitter<any> = new EventEmitter()
  @Input() mini: boolean = false
  searchTerm: string;
  styles: any;
  group: Array<any>
  newFrameColor: string;
  linear: boolean;
  circular: boolean;
  angle: any;
  newgradient: string;
  color: any;
  currentdirection: any;
  multilayer: any;
  selectedlayer: void;
  layers: any;
  radialoptions: string[];
  radialextend: string[];
  radialshape2: string;
  radialshape: any;
  darkmode: boolean = false;
  repeating: any;
  image: boolean;
  imageurl: string;
  @Output()
  output = new EventEmitter();
  imageoptions: string[];
  imageoption: any;
  bgimgcustomsize: boolean;
  bgimgsize: string;
  imgrepeat: any;
  repeatoption: any;
  bgimgcustom: boolean;
  backgroundrp: string[];
  testvalue: any;
  editmode: boolean = false;
  module: {
    image: boolean;
    create: boolean;
    gallery: boolean;
  }
  @ViewChild('dynamicJoystick') dynamicJoystick: NgxJoystickComponent;
  dynamicOptions: JoystickManagerOptions = {
    mode: 'dynamic',
    color: 'red',
    multitouch: true
  };

  constructor(
    public css: CssService,
    public modalCtrl: ModalController ) { 
      this.images = this.css.gradients
      this.styles = this.css.gradients;
    }

  selectRepeat(value) {
    this.repeatoption = value;
  }

  ngOnInit() {
    this.module = {
      image: false,
      create: true,
      gallery: false
    }
    this.group = []
    this.layers = []
    this.newFrameColor = 'liner-gradient(#e1eec3, #f05053)';

    this.radialoptions = ['circle', 'ellipse']
    this.radialextend = ['top', 'bottom', 'right', 'left', 'center']
    this.imageoptions = ['contain', 'cover', 'auto', 'custom']
    this.backgroundrp = ['inherit', 'repeat', 'round', 'space', 'no-repeat']

    this.selectColor()
    this.makegradient()
    this.darkmode = true;
  }

  selectPremade(val) {
    this.selected.emit(val)
  }

  close() {
    this.modalCtrl.dismiss().catch(err => {
      if(this.mini) {
        return
      } else {
        //Close with router
      }
    })
  }

  toggle(value) {
    switch(value) {
      case 'create':
        this.module.create = true;
      case 'import':
        this.module.create = false;
      case 'saved':
        this.module.gallery = true;
    }
  }

  dragSome(event){
    console.log(event);
    this.testvalue = event;
    this.angle += event;
    if(this.angle >= 360) {
      this.angle = 360
    }
    if(this.angle <= 0){
      this.angle = 0
    }
  }
  imageset(value) {
    if(value == 'custom'){
      this.bgimgcustom = true;
    } else {
      this.bgimgcustom = false;
    }
    this.imageoption = value
  }
  bgsizechage(num) {
    this.bgimgcustom = true;
    this.bgimgsize = num + '%';
    this.imageoption = num + '%';
  }
  makegradient(){
    //console.log(this.group)
    let data = this.group
    let colors: any = data.forEach((color, index) => {
      if(index = this.group.length) {
        return + color
      } else {
        return color + ','
      }
    });
    let degree = this.getdegree()
    let gradient = this.getgradient()
    let almost = data.toString()
    let r = almost.replace(',', ', ')
    let k = r.replace(',#', ', #')
    //console.log(almost, k, this.replacestuff(almost))
    if(this.linear) {
      this.newgradient = gradient + '(' + degree + k + ')';
    }
    if(this.circular) {
      this.newgradient = gradient + '(' + this.radialshape + " at " + this.radialshape2 + ", " + k + ')';
    }
    if(this.image) {
      this.newgradient = 'url(' + this.imageurl + ')'
    }
    this.newFrameColor = this.newgradient;
     if(!this.multilayer) {
      this.newFrameColor = this.newgradient;
     }
     if(this.multilayer = true) {
       let singlelayer = this.newgradient + ','
       this.selectedlayer = this.getselectedlayer()
     }
    console.log(this.newgradient, this.newFrameColor)
    this.output.emit(this.newFrameColor)
  }
  replacestuff(value) {
    //console.log(value)
    var almost = value;
    let r = almost.replace(',', ', ');
    let k = r.replace(',#', ', #');
    //console.log(almost)
    return almost
  }
  replacecomma(value) {
    return value.replace(',', ', ');
  }
  replacehash(value) {
    return value.replace(',#', ', #');
  }
  getselectedlayer() {

  }
  newcolor() {
    this.group.push('#ff0000')
  }

  Color(color, index) {
    //console.log(color.target.value);
    this.group[index] = color.target.value;
    //console.log(this.group, color.target.value, index);
    this.makegradient();
  }

  getdegree() {
    if(!this.angle && !this.currentdirection){
      return 90 + 'deg' + ', '
    } else {
      if(this.currentdirection) {
        return this.currentdirection;
      } else {
        return this.angle + 'deg' + ', ';
      }
    }
  }
  anglechage(event) {
    this.currentdirection = null
    //console.log(event)
    this.makegradient()
  }
  type(valu) {
    if(valu === 'L') {
      this.linear = true;
      this.circular = false;
      this.image = false;
      this.converttolinear()
    }
     if( valu === 'R') {
      this.linear = false;
      this.circular = true;
      this.image = false
      this.converttocircular()
    }
    if( valu == 'I'){
      this.image = true;
      this.linear = false;
      this.circular = false;
      if(!this.imageurl) {
        this.unsplash()
      }
    }
    this.makegradient()
    //console.log(this.linear, this.circular)
  }
  converttolinear() {
    this.currentdirection = null
  }

  unsplash() {
    if(this.module.image) {
      this.module.image = false
    } else {
      this.module.image = true
    }
  }

  selectGr(value, name) {
    this.imageurl = value
    this.makegradient()
  }

  converttocircular() {
    console.log(this.currentdirection, this.linear, this.getdegree())
    let current = this.getdegree() 
    let ne = 'circle at 100%, '
    this.currentdirection = ne
  }
  select(value) {
    this.radialshape = value
    if(this.radialshape2) {
      this.makegradient()
    }
  }

  select2(value) {
    this.radialshape2 = value;
    this.makegradient()
  }
  newlayer() {
    this.layers.push(this.newgradient);
    this.newgradient = null;
    this.group = [];
    this.multilayer = true;
  }
  selectlayer(value, index){
    this.newgradient = value;
    this.selectedlayer = index;
  }
  getgradient() {
    if(this.linear){
      if(!this.repeating) {
      return 'linear-gradient'
      } else {
        return 'repeating-linear-gradient'
      }
    } if(this.circular) {
      if(!this.repeating) {
      return 'radial-gradient'
      } else {
        return 'repeating-radial-gradient'
      }
    } else {
      return 'conic-gradient'
    }
  }

  getdirection(value: string){
    let data = []
    if(value.includes('to')) {
      data.push('to')
        if(value.includes('top')){
          data.push('top')
        }
        if(value.includes('bottom')){
          data.push('bottom')
        }
        if(value.includes('right')){
          data.push('right')
        }
        if(value.includes('left')){
          data.push('left');
        }
        let string = data.toString()
        this.angle = 90;
        this.setdirection(string);
        this.anglechage(value)
        return this.replacecomma(string)
    }
    if(value.includes('at')) {
      if(value.includes('circle')){
        this.circular = true;
        this.radialshape = 'circle'
        data.push('circle at')
      }
      if(value.includes('ellipse')){
        this.circular = true;
        this.radialshape = 'ellipse'
        data.push('ellipse at')
      }
      if(value.includes('top')){
        data.push('top')
        this.radialshape2 = 'top'
      }
      if(value.includes('bottom')){
        data.push('bottom')
        this.radialshape2 = 'bottom'
      }
      if(value.includes('right')){
        data.push('right')
        this.radialshape2 = 'right'
      }
      if(value.includes('left')){
        data.push('left');
        this.radialshape2 = 'left'
      }
      if(value.includes('center')){
        data.push('center')
        this.radialshape2 = 'center'
      }
      if(value.includes('closest-side')){
        data.push('closest-side')
        this.radialshape2 = 'closest-side'
      }
      if(value.includes('closest-corner')){
        data.push('closest-corner')
        this.radialshape2 = 'closest-corner'
      }
      if(value.includes('farthest-side')){
        data.push('farthest-side')
        this.radialshape2 = 'farthest-side'
      }
      if(value.includes('farthest-corner')){
        data.push('farthest-corner');
        this.radialshape2 = 'this.radialshape2'
      }
      this.setdirection(data.toString());
        return data.toString()
    }
  }
  setdirection(value) {
    this.currentdirection = this.replacecomma(value)
  }
  getcolors(value) {
    let newval = value.split(' ')
    //console.log(newval);
    let variables = 'to|right|bottom|left|top'
    newval.forEach((element, index) => {
      //console.log(element.search(variables), element, index)
      if(element.search(variables) == 0) {
        newval.splice(index, 1)
      }
    });
    newval.forEach((element, index) => {
      //console.log(element.search(variables), element, index)
      if(element.search(variables) == 0) {
        newval.splice(index, 1)
      }
    });
    this.group = [];
    newval.forEach((element, index) => {
      if(element.includes(',')){
        let lastcheck = element.replace(',', '')
        this.group.push(lastcheck)
      } else {
        this.group.push(element)
      }
    })
    console.log(newval, this.group);
  }
  selectColor() {
    //console.log(this.newFrameColor)
    let data = this.newFrameColor
    let type ='linear-gradient|radial-gradient|'
    let linear = data.includes('linear-gradient');
    let radial = data.includes('radial-gradient');
    let conic = data.includes('conic-gradient')
    let hexcolor = data.includes('#')
    console.log(linear, radial)
    if(linear){
      this.linear = true
      this.circular = false
      this.angle = 10;
      let des = data.replace('linear-gradient(', '')
      let des2 = des.replace(')', '')
      let des25 = des2.replace(',', '')
      let des3 = this.getdirection(des25)
      let des4 = this.getcolors(des25)
      console.log(des3, des4)
    }
    if(radial){
      this.circular = true
      this.linear = false
      let res = data.replace('radial-gradient(', '')
      let res2 = res.replace(')', '')
      let res25 = res2.replace(",", '')
      let resplit = res25.split(' ')
      this.getdirection(res25)
      console.log(res25)
      resplit.forEach(element => {
        if(element.includes('#')) {
          this.rmthenadd(element)
        }
      })
    }
    if(!radial && !conic && !linear && hexcolor) {
      this.group = []
      this.group.push(data)
    }
    //console.log(this.linear, this.circular, data)
    let cal = type.toLowerCase().search(this.newFrameColor);
    //console.log(cal);
    this.makegradient()
  }
  rmthenadd(value) {
    let ne = value
    if(ne.includes(')')){
      ne.replace(')', '')
    }
    if(ne.includes(',')){
      ne.replace(',', '')
    }
    if(!ne.includes(')') && !ne.includes(',')) {
      this.group.push(ne)
      return
    } else {
      this.rmthenadd(ne)
    }
  }
}
