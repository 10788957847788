import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'borderSort'})
export class BorderSortPipe implements PipeTransform {
  transform(items: Array<any>, selected?: boolean) {
    var val = []
    if(selected === true) {
      val = items.filter((element) => element.name.includes(" "));
    } else {
      val = items.filter((element) => !element.name.includes(" "));
    }
    console.log('FIltered Val')
    console.log(val)
    return val
  }
}