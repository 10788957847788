import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { logEvent } from 'firebase/analytics';
import { environment } from 'src/environments/environment';
import { analytics } from '../app.module';

declare let gtag: Function;
declare let fbq: Function;
declare let snaptr: Function;

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {
  ftag: any;
  currentUser: any;
  constructor(
    public snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    public checkserver() {
      if (isPlatformBrowser(this.platformId) === true) {
        return true;
      } else {
        return false;
      }
    }

  logEvent(eventName: string, eventParams?: {[key: string]: any;}) {
    if(environment.production){
      logEvent(analytics, eventName, eventParams)
    } else {
      return 
    }
  }

  warnmessage(string) {
    return this.snackBar.open(string, 'Ok!', {
      duration: 3000,
      panelClass: 'warn'
    });
  }

  usermessage(string) {
    return this.snackBar.open(string, 'Ok!', {
      duration: 3000,
    });
  }

  public initfb(id) {
    if (isPlatformBrowser(this.platformId) === true) {
      this.ftag = id
      if (fbq && fbq !== undefined || null) {
        fbq('init', id);
      }
    }
  }

  public initsnap(id) {
    if (isPlatformBrowser(this.platformId) === true) {
      this.ftag = id
      if (fbq && fbq !== undefined || null) {
        snaptr('init', id);
      }
    }
  }

  public snapinit(pixel, type: string, data) {
    const snap = snaptr
    if (environment.production) { 
        if(snaptr && snaptr !== undefined || null) {
          if(type && data !== undefined || null) {
            snaptr('init', pixel, {[type]: data});
          } else {
            snaptr('init', pixel);
          }
        } else {
          let snapobj = {[type]: data}
          let initsnap = "snaptr('init'," + pixel + "," + snapobj + ");"
          let snap = document.createElement('script')
              snap.setAttribute('type', 'text/javascript')
              snap.textContent = `<!-- Snap Pixel Code -->
              (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
              {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
              a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
              r.src=n;var u=t.getElementsByTagName(s)[0];
              u.parentNode.insertBefore(r,u);})(window,document,
              'https://sc-static.net/scevent.min.js');
              `;{initsnap};`
              <!-- End Snap Pixel Code -->`
              this.document.head.appendChild(snap)
        }
    }
  }

  public async snaptrack(pixel, type: string, data) {
    const snap = snaptr
    const snappixel = pixel ? pixel : 'ba260fc7-4b55-437c-b025-f508281b78da';
    if (environment.production) { 
        if(snaptr && snaptr !== undefined || null) {
          if(!pixel) {
            snaptr({[type]: data})
          } else {
            snaptr({[type]: data});
          }
        } else {
          let snapobj = {'user_email':  this.currentUser.email }
          let initsnap = "snaptr('init'," + snappixel + "," + snapobj + ");"
          let snap = document.createElement('script')
              snap.setAttribute('type', 'text/javascript')
              snap.textContent = `<!-- Snap Pixel Code -->
              (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
              {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
              a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
              r.src=n;var u=t.getElementsByTagName(s)[0];
              u.parentNode.insertBefore(r,u);})(window,document,
              'https://sc-static.net/scevent.min.js');
              `;{initsnap};`
              `;{snapobj};`
              <!-- End Snap Pixel Code -->`
              this.document.head.appendChild(snap)
        }
    }
  }

}
