import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CssService } from '../../services/css.service';

@Component({
  selector: 'app-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss'],
})
export class ColorComponent implements OnInit {
  color: { custom: any; input: any; name: any; };
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Input() limit: any;
  @Input() inputcolor;
  
  final: any;
  constructor(public css: CssService) { 

  }

  ngOnInit() {
    this.color = {
      custom: null,
      input: null,
      name: null
    }
  }

  public product = ((val) => {
    console.log('Product is pressed')
    this.selected.emit(val)
  })

  removecolor(val) {
    this.color[val] = null
  }

}
